import React, { Component } from "react";
import '../App.css';
import {Howl, Howler} from 'howler';
import Sound from "../beep.wav";

class Stage6 extends Component {

    constructor(props) {
        super(props);
    }

    SoundPlayLeft = (src) => {
        const sound = new Howl({
            src
        })
        sound.stereo(-1)
        sound.play()

        
    }
    SoundPlayRight = (src) => {
        const sound = new Howl({
            src
        })
        sound.stereo(1)
        sound.play()
    }

    loopSoundLeft = () => {
        if(this._isMounted === true) {
            this.SoundPlayLeft(Sound)
            setTimeout(this.loopSoundLeft, 1600); 
        }
    }

    loopSoundRight = () => {
        if(this._isMounted === true) {
            this.SoundPlayRight(Sound)
            setTimeout(this.loopSoundRight, 1600); 
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.loopSoundLeft();
        setTimeout(this.loopSoundRight, 800);
        setTimeout(this.nextStage, 18000);
    }

    nextStage = () => {
        this.props.handleNext();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }


    render () {
        Howler.volume(1.0)
        
        return (
            <div className="stage1">
                <div className="dot"></div>
            </div>

        )
    }

}

export default Stage6;