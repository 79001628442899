import React, { Component } from "react";
import '../App.css';
import Button from 'react-bootstrap/Button';

class Stage4_4 extends Component {

    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        this.props.handleNext();
    }

    render () {

        return (
            <div className="stage1">
                <h1>Focus on this while you follow the blue dot with your eyes.</h1>
                <div className="targetContainer">
                    <div className="target">{this.props.detail}</div>
                </div>
                <div className="start startButton">
                    <Button className="start" onClick={this.handleClick}>Start EMDR</Button>
                </div>
            </div>

        )
    }

}

export default Stage4_4;