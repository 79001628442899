import React from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';


function LovingKindness(props) {
    return (
            
            <Accordion style={{width: '80%', margin:'10px'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p style={{fontWeight: 'bold'}}>Loving Kindness Meditation</p>
                            <p style={{color: 'gray'}}> A mindfulness technique</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{padding: '20px'}}>
                            <p>Mantra: "May you be happy, may you be healthy, may you be loved."</p>
                            <p>Use the above mantra with this four step mediation practice.</p>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>1.) Visualize someone you love. Say this mantra to them as if they were there with you.</p>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>2.) Visualize someone who has been challenging to deal with. Say this mantra to them.</p>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>3.) Visualize yourself. Say this matra to yourself.</p>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>4.) Visualize the entire world infront of you. Say this mantra to the world.</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        
    );
}

export default LovingKindness;