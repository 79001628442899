import React from 'react';

function Checkout(props) {
    return (
        <div>
            <form action="/create-setup-intent" method="POST">
                <button>SUBSCRIBE!</button>
            </form>
        </div>
    );
}

export default Checkout;