import { React, Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

import app from "../firebase"
import CheckoutForm from './CheckoutForm';
import NotSignedIn from './NotSignedIn';
import NotVerified from './NotVerified';

import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
  } from "firebase/functions";
const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(stripePublishableKey);
const functions = getFunctions(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth();


class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            components: [],
            loading: true,
        }
    }


    renderPaymentElement(secret, uid, email) {

        const element = <Elements stripe={stripePromise} options={{clientSecret: secret}}>
                            <CheckoutForm uid={uid} email={email}/>
                        </Elements>
        this.setState({components: [element]});
        this.setState({loading: false});
    }

    updateUserDoc(uid, customerId, subscriptionId) {
        const userRef = doc(db, "users", uid);
        updateDoc(userRef, {
            customerId: customerId,
            subscriptionId: subscriptionId,
        });
    }

    createSetupIntent(user) {
        const sendText = httpsCallable(functions, 'createSetupIntent')
        return sendText(user.uid, 'more stuff')
        .then(async (response) => {
            this.renderPaymentElement(response.data, user.uid, user.email);
        })
    };

    async getSubscriptionStatus(user) {
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);
        if(docSnap.data().subscriptionId) {
            const sendText = httpsCallable(functions, 'getSubscriptionStatus')
            return sendText(docSnap.data().subscriptionId, 'more stuff')
            .then(response => {
                if(response.data.status === 'active' || response.data.status === 'trialing') {
                    //user has an active subscription...
                    this.setState({loading: false});
                    this.setState({components: [<div><h1> Your subscription to Nurosync Pro is active!</h1><h3 style={{textAlign: 'center'}}>To manage your subscription, go to the account info page.</h3></div>]});
                } else {
                    //user has no active subscription, render payment element...
                    this.createSetupIntent(user);
                }
            });
        } else { 
            //no existing subscription...       
            this.createSetupIntent(user);
        }
    }


    componentDidMount() {
        logEvent(analytics, 'checkout_page_loaded');

        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            //find user object then add data to document...
                if(user.emailVerified) {
                    //check user's subscription status
                    this.getSubscriptionStatus(user);

                    // fetch('/create-setup-intent', {
                    //     method: 'post',
                    //     headers: {
                    //     'Content-Type': 'application/json',
                    //     },
                    //     body: JSON.stringify({
                    //     email: user.email,
                    //     name: "First Customer",
                    //     }),
                    // }).then(async (r) => {
                    //     const response = await r.json();
                    //     this.renderPaymentElement(response.clientSecret, user.uid, user.email);
                    // })
                } else {
                    //user is not verified...
                    this.setState({loading: false});
                    this.setState({components: [<NotVerified />]});
                }
            // ...
            } else {
            // User is signed out...
                this.setState({loading: false});
                this.setState({components: [<NotSignedIn />]});
            }
        });



    }

    render() {
        return (
            <div style={{display: "block", width: '100%', margin: "auto"}}>

                <div style={{padding: "20px", margin: "auto", maxWidth: '500px'}}>
                    {this.state.components}
                </div>
                <div className="logo" style={{display: this.state.loading === false ? "none": "",}} >
                    <img style={{width:'50px', height:'50px', margin: '100px'}}src="https://media.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"/>
                </div>
            </div>

        );
    }

}

export default Subscribe;