import React, { useRef, useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { UserAuth } from '../context/AuthContext';
import { getFirestore, collection, addDoc, doc, setDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

import app from "../firebase"

const db = getFirestore(app);
const analytics = getAnalytics(app);

// const db = getFirestore(app);
//TODO: 
// Make sure passwords match before submitting.
// Give warning error if password is insufficient length.
// Make sure no duplicate accounts with the same email is created.
// Confirm email address with an email.

const Signup = () => {

    const { createUser } = UserAuth();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();

    const addUserData = async (name, email, uid) => {
            await setDoc(doc(db, "users", uid), {
                name: name,
                email: email
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true);
        if(password === passwordConfirmation) {
            createUser(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                // const user = userCredential.user;
                const name = firstName + ' ' + lastName;
                // ...
                sendEmailVerification(auth.currentUser)
                .then(() => {
                    // Email verification sent!
                    // ...
                    addUserData(name, email, userCredential.user.uid);
                    logEvent(analytics, 'account_created');

                    navigate('/accountcreated');
                });

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setError(error.message);
                setIsProcessing(false);
                // ..
            });

        } else {
            //passwords do not match...
            setError("Passwords do not match.")
            setIsProcessing(false);
        }



    }
    

    return (
        <div className='container'>
        <div style={{width: "400px", padding: "20px"}}>
            <Card>
                <Card.Body>
                    <h3>Sign Up</h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" id="email">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" required onChange={(e) => setFirstName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" id="email">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" required onChange={(e) => setLastName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" required onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" required onChange={(e) => setPassword(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" id="password-confirmation">
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type="password" required onChange={(e) => setPasswordConfirmation(e.target.value)}/>
                            
                        </Form.Group>
                        <div style={{color: 'red'}}>{error}</div>
                        <Button disabled={isProcessing} variant="primary" type="submit">Sign Up</Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Already have an account? <a href="/signin">Sign in</a>
            </div>
            
        </div>
        </div>
    );
}

export default Signup