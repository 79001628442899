import React from 'react';
import { useState } from 'react';
import {
	FaAngleRight,
	FaAngleLeft, 
	FaChartBar, 
	FaThLarge, 
	FaTools, 
	FaCog,
	FaSignOutAlt,
	FaBars,
	FaEye,
} from 'react-icons/fa';
import { RiPsychotherapyFill } from 'react-icons/ri';
import { NavLink } from "react-router-dom";

import "../../navbar.css";
import Anxiety from '../protocols/Anxiety';
import AnxietyTestResults from '../results/AnxietyTestResults';
import AnxietyEMDRResults from '../results/AnxietyEMDRResults';
import AnxietyTest from '../tools/AnxietyTest';
import SevenEleven from '../tools/SevenEleven';
import VisualDetails from '../tools/VisualDetails';
import AnxietyDashboardView from '../dashboardViews/AnxietyDashboardView';
import GuidedBreathing from '../tools/GuidedBreathing/GuidedBreathing';

const ICON_SIZE = 20;

const dashboard = <h1>Coming Soon!</h1>
const results = <div className='dashboardView'><AnxietyTestResults /><AnxietyEMDRResults /></div>


function Navbar() {	
	
	const showComponent = (component) => {
		setComponent(component)
	}

	const [activeComponent, setComponent] = useState(<div className="dashboardView"><AnxietyDashboardView showComponent={showComponent}/></div>);
	const [navVisible, showNavbar] = useState(window.innerWidth > 600);
	let visible = navVisible 
	let show = showNavbar


	return (

		<div className='dashboard'>
			<div className="mobile-side">
				<button
					className="mobile-side-btn"
					onClick={() => show(!visible)}
				>
					<FaBars size={24}  />
				</button>
			</div>
			<nav id="side-drawer" className={!visible ? 'sidebar' : ''}>
				<button
					type="button"
					className="side-btn"
					onClick={() => show(!visible)}
				>
					{ !visible
						? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
				</button>
				<div>

					<div className="links side-top">
						<div  onClick={() => showComponent(<AnxietyDashboardView showComponent={showComponent}/>)}className="side-link">
							<FaThLarge size={ICON_SIZE} />
							<span>Dashboard</span>
						</div>
						<div onClick={() => showComponent(<Anxiety />)} className="side-link">
							<FaEye size={ICON_SIZE} />
							<span>EMDR</span>
						</div>
						<div onClick={() => showComponent(<div className='dashboardView'><AnxietyTest /><SevenEleven /><VisualDetails /><GuidedBreathing showComponent={showComponent} /></div>)} className="side-link">
							<FaTools size={ICON_SIZE} />
							<span>Tools</span> 
						</div>
						<div onClick={() => showComponent(results)} className="side-link">
							<FaChartBar size={ICON_SIZE} />
							<span>Results </span>
						</div>

					</div>
				</div>
			</nav>
			<div id="main" className={!navVisible ? "page" : "page page-with-navbar"}>
				<div id="scroller" className='dashboardView'>
					{activeComponent}
				</div>
			</div>
		</div>
  );
}

export default Navbar;
