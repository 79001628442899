import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../App.css';

class Stage1 extends Component {

    constructor(props) {
        super(props);
        this.handleClick.bind(this);

    }

    handleClick = () => {
        this.props.handleNext();
    }

    handleSubmit = event => {
        event.preventDefault();
    }

    render () {

        return (
            <div className="stage1">
                <h1> How are you feeling today? Is anything on your mind?</h1>
                <div className="textBox">
                    <Form onSubmit={this.handleSubmit}>
                        <fieldset>
                            <Form.Group className="mb-3">
                            <Form.Label htmlFor="textInput"></Form.Label>
                            <Form.Control id="textInput" placeholder="..." />
                            </Form.Group>
                        </fieldset>
                    </Form>
                </div>
                <div className="start startButton">
                        <Button className="start" onClick={this.handleClick}>Next</Button>
                </div>
            </div>

        )
    }

}

export default Stage1;