import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, } from "firebase/firestore";
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
  } from "firebase/functions";

import { AiFillLock } from 'react-icons/ai';

import app from "../firebase"


const functions = getFunctions(app);
const auth = getAuth();
const db = getFirestore(app);

function NavBar() {
    const auth = getAuth();

    const handleSignOut = async function() {
        try{
            await signOut(auth);
            // await logout
            setLogOut(true);
        } catch (e) {
            // console.log(e.message)
        }
    }

    const loadingGif = [
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img style={{width: '25px', height:'25px', margin: '20px'}}src="https://media.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />
        </div>
    ]

    const inactiveSubscriptionMenu = [
        <div>
            <NavDropdown.Item href="/therapy"><p className="nav-text">General</p></NavDropdown.Item>
            <NavDropdown.Item href="/anxiety" style={{display: 'flex', alignItems: 'center'}}>
                <p className="nav-text-disabled">Anxiety</p>
                <AiFillLock className="nav-text-disabled" style={{position: 'relative', bottom: '10px'}} size={40}/>
            </NavDropdown.Item>
            <NavDropdown.Item href="/depression" style={{display: 'flex', alignItems: 'center'}}>
                <p className="nav-text-disabled">Depression</p>
                <AiFillLock className="nav-text-disabled" style={{position: 'relative', bottom: '10px'}} size={40}/>
            </NavDropdown.Item>
            <NavDropdown.Item href="/trauma" style={{display: 'flex', alignItems: 'center'}}>
                <p className="nav-text-disabled">Trauma</p>
                <AiFillLock className="nav-text-disabled" style={{position: 'relative', bottom: '10px'}} size={40}/>
            </NavDropdown.Item>
        </div>
    ]
    const activeSubscriptionMenu = [
        <div>
            <NavDropdown.Item href="/therapy"><p className="nav-text">General</p></NavDropdown.Item>
            <NavDropdown.Item href="/anxiety"><p className="nav-text">Anxiety</p></NavDropdown.Item>
            <NavDropdown.Item href="/depression"><p className="nav-text">Depression</p></NavDropdown.Item>
            <NavDropdown.Item href="/trauma"><p className="nav-text">Trauma</p></NavDropdown.Item>
        </div>
    ]
    const signedInAccountMenu = [
        <div>
            <NavDropdown.Item href="/account"><p className="nav-text">Account Info</p></NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleSignOut}><p className="nav-text">Sign Out</p></NavDropdown.Item>
        </div>
    ]
    const signedOutAccountMenu = [
        <div>
            <NavDropdown.Item href="/signin"><p className="nav-text">Sign In</p></NavDropdown.Item>
            <NavDropdown.Item href="/signup"><p className="nav-text">Sign Up</p></NavDropdown.Item>
        </div> 
    ]

    const [therapyMenu, setTherapyMenu] = useState(loadingGif);
    const [accountMenu, setAccountMenu] = useState(loadingGif);
    const [user, setUser] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [logOut, setLogOut] = useState(false);


    const getSubscriptionStatus = async (uid) => {  
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if(docSnap.data().subscriptionId) {
            const sendText = httpsCallable(functions, 'getSubscriptionStatus')
            return sendText(docSnap.data().subscriptionId, 'more stuff')
            .then(response => {
                if(response.data.status === 'trialing' || response.data.status === 'active') {
                    // User has active subscription...
                    setTherapyMenu(activeSubscriptionMenu);
                } else {
                    // User has inactive subscription...
                    setTherapyMenu(inactiveSubscriptionMenu);
                }
            });
        } else {
            // No subscription id on user document...
            setTherapyMenu(inactiveSubscriptionMenu);
        }

    }
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if(currentUser) {
                //user is signed in...
                setUser(currentUser);
                setAccountMenu(signedInAccountMenu);
                // !!CHECK USER VERIFY STATUS?
                getSubscriptionStatus(currentUser.uid);
            } else {
                //user is signed out...
                setUser(false);
                setAccountMenu(signedOutAccountMenu);
                setTherapyMenu(inactiveSubscriptionMenu);
            }

        })
        return() => {
            unsubscribe();
        }
    },[])

  return (
    <div className="App application">
        <Navbar collapseOnSelect expand="lg" bg="myBlue" variant="dark">
            <Container>
                <Navbar.Brand href="/">            
                    <img
                        src="nurosync_logo_small.svg"
                        width="30"
                        height="30"
                        className="d-inline-block align-top navLogo"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">

                    <Nav.Link className="nav-dropdown-link" href="/pro"><p className="nav-text">Nurosync Pro</p></Nav.Link>
                    <Nav.Link href="/about"><p className="nav-text">About</p></Nav.Link>
                    <Nav.Link href="/mobile"><p className="nav-text">Mobile</p></Nav.Link>
                    <NavDropdown className="nav-text" title="Therapy" id="collapsible-nav-dropdown">
                        {therapyMenu}
                    </NavDropdown>
                    
                </Nav>
                <Nav>
                    <Nav.Link href="/faq">
                        <p className="nav-text">FAQs</p>
                    </Nav.Link>
                    <Nav.Link eventKey={2} href="/contact">
                        <p className="nav-text">Contact</p>
                    </Nav.Link>
                        <NavDropdown className="nav-text" title="Account" id="collapsible-nav-dropdown">
                            {accountMenu}
                        </NavDropdown> 
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
  );
}

export default NavBar;
