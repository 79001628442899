import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, doc, setDoc, arrayUnion } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import '../../../App.css';
import app from "../../../firebase"

const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth();

class Results extends Component {

    constructor(props) {
        super(props);

    }



    addData = async (uid) => {
        const docRef = doc(db, 'traumaEMDRResults', uid);
        setDoc(docRef, {
            scores : arrayUnion({
                traumaScore1: this.props.score1,
                traumaScore2: this.props.score2,
                positiveScore1: this.props.posScore1,
                positiveScore2: this.props.posScore2,
                date: new Date(Date.now()).toLocaleString(),
            })
            
        }, {merge: true});


    }

    componentDidMount() {
        logEvent(analytics, 'trauma_session_completed');

        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
                let uid = user.uid;
                this.addData(uid);
            
            // ...
            } else {
            // User is signed out
            // ...
                this.addData(null);

            }
        });

    }




    render () {
        return (
            <div className="stage0">
                <h1>Session complete. Please take a few moments to ground yourself before moving on with your day.</h1>
                <h1>Results:</h1>
                <div className="resultsTable"> 
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Before</th>
                                <th>After</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Distress Score</th>
                                <td>{this.props.score1}</td>
                                <td>{this.props.score2}</td>
                            </tr>
                            <tr>
                                <th>Positive Belief Score</th>
                                <td>{this.props.posScore1}</td>
                                <td>{this.props.posScore2}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="start startButton">
                        <Button className="start" href="/">Done</Button>
                </div>
                {/* <div className="logo"><img className="bigLogo" src="nurosync_logo.png"></img></div> */}
            </div>
        )
    }

}

export default Results;