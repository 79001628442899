import React from 'react';

import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';


function Chart(props) {
    return (
        <div>
            <BarChart width={400} height={400} data={props.data} style={{margin: "auto"}}>
                <XAxis dataKey="date"/>
                <YAxis domain={[0, 10]}/>
                {/* <Line type="monotone" dataKey={props.dataKey1} stroke="#8884d8" name="Before"/> */}
                {/* <Line type="monotone" dataKey={props.dataKey2} stroke="#82ca9d" name="After"/> */}
                <Tooltip />
                <Bar dataKey={props.dataKey1} barSize={30} name="Before" fill="#8884d8"/>
                <Bar dataKey={props.dataKey2} barSize={30} name="After" fill="#82ca9d"/>

            </BarChart>
        </div>
    );
}

export default Chart;