import { textAlign } from '@mui/system';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { AiFillCheckSquare } from 'react-icons/ai';
import { AiFillCloseSquare } from 'react-icons/ai';
import { FaBorderAll } from 'react-icons/fa';


function Checklist(props) {
    return (
        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Table hover style={{width: '500px', backgroundColor: 'whitesmoke', borderRadius: '15px'}}>
                <thead>
                    <tr>
                        <th></th>
                        <th style={{textAlign: 'center'}}>Nurosync Basic (free)</th>
                        <th style={{textAlign: 'center'}}>Nurosync Pro ($4.99/month) </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>General EMDR protocol</td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>General Therapy Tools</td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>General Therapy Results</td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>Anxiety EMDR protocol</td>
                        <td style={{color: '#c9465e', textAlign: 'center'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>Anxiety Therapy Tools</td>
                        <td style={{color: '#c9465e', textAlign: 'center'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>Anxiety Therapy Results</td>
                        <td style={{color: '#c9465e', textAlign: 'center'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>Depression EMDR protocol</td>
                        <td style={{color: '#c9465e', textAlign: 'center'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>Depression Therapy Tools</td>
                        <td style={{color: '#c9465e', textAlign: 'center'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>Depression Therapy Results</td>
                        <td style={{color: '#c9465e', textAlign: 'center'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>Trauma/PTSD EMDR protocol</td>
                        <td style={{color: '#c9465e', textAlign: 'center'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td>Trauma/PTSD Therapy Tools</td>
                        <td style={{color: '#c9465e', textAlign: 'center'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                    <tr>
                        <td style={{borderWidth: '0px'}}>Trauma/PTSD Therapy Results</td>
                        <td style={{color: '#c9465e', textAlign: 'center', borderWidth: '0px'}}><AiFillCloseSquare size={36}/></td>
                        <td style={{color: '#66CC50', textAlign: 'center', borderWidth: '0px'}}><AiFillCheckSquare size={36}/></td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default Checklist;