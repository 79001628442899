import React, { Component } from "react";

import '../../App.css';
import Stage0 from "./ptsdStages/Stage0";
import Stage1 from "./ptsdStages/Stage1";
import Stage2 from "./ptsdStages/Stage2";
import Sensory from "./ptsdStages/Sensory";
import Emotional from "./ptsdStages/Emotional";
import Physical from "./ptsdStages/Physical";
import Stage3 from "./ptsdStages/Stage3";
import Stage4 from "./ptsdStages/Stage4";
import Stage5 from "./ptsdStages/Stage5";
import Stage6 from "./ptsdStages/Stage6";
import Stage7 from "./ptsdStages/Stage7";
import Stage8 from "./ptsdStages/Stage8";
import Stage9 from "./ptsdStages/Stage9";
import Stage10 from "./ptsdStages/Stage10";
import Stage11 from "./ptsdStages/Stage11";
import Stage12 from "./ptsdStages/Stage12";
import Stage13 from "./ptsdStages/Stage13";
import Results from "./ptsdStages/Results";



import BilateralStimulation from "../BilateralStimulation";
import Breathwork from "../Breathwork";

class PTSD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 0,
            score1: null,
            score2: null,
            posScore1: null,
            posScore2: null,
            trauma: null,
            detail: null,
            sensory: null,
            physical: null,
            emotional: null,
            positive: null,
            negative: null,
        };
    };

    handleNext = () => {
        document.getElementById("scroller").scroll(0,0)
        this.setState({ stage: this.state.stage + 1 });
    }   
    handleTraumaSet = (newText) => {
        this.setState({ trauma: newText });
    }   
    handleDetailSet = (newText) => {
        this.setState({ detail: newText });
    }   
    handleSensorySet = (newText) => {
        this.setState({ sensory: newText });
    }   
    handlePhysicalSet = (newText) => {
        this.setState({ physical: newText });
    }   
    handleEmotionalSet = (newText) => {
        this.setState({ emotional: newText });
    }   
    handleScore1Set = (newScore) => {
        this.setState({ score1: newScore });
    }
    handleScore2Set = (newScore) => {
        this.setState({ score2: newScore });
    }
    handleNegativeSet = (newText) => {
        this.setState({ negative: newText });
    }   
    handlePositiveSet = (newText) => {
        this.setState({ positive: newText });
    }
    handlePosScore1Set = (newScore) => {
        this.setState({ posScore1: newScore });
    }
    handlePosScore2Set = (newScore) => {
        this.setState({ posScore2: newScore });
    }

    render() {
        return (
            <div className="emdr">
                {this.state.stage === 0 &&
                    <Stage0 handleNext={this.handleNext} />
                }
                {this.state.stage === 1 &&
                    <Stage1 handleNext={this.handleNext} />
                }
                {this.state.stage === 2 &&
                    <Stage2 handleTraumaSet={this.handleTraumaSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 3 &&
                    <Stage3 trauma={this.state.trauma} handleDetailSet={this.handleDetailSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 4 &&
                    <Sensory trauma={this.state.trauma} handleSensorySet={this.handleSensorySet} handleNext={this.handleNext} />
                }
                {this.state.stage === 5 &&
                    <Physical trauma={this.state.trauma} handlePhysicalSet={this.handlePhysicalSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 6 &&
                    <Emotional trauma={this.state.trauma} handleEmotionalSet={this.handleEmotionalSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 7 &&
                    <Stage4 trauma={this.state.trauma} detail={this.state.detail} sensory={this.state.sensory} physical={this.state.physical} emotional={this.state.emotional} handleScore1Set={this.handleScore1Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 8 &&
                    <Stage5 trauma={this.state.trauma} detail={this.state.detail} sensory={this.state.sensory} physical={this.state.physical} emotional={this.state.emotional} handleNegativeSet={this.handleNegativeSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 9 &&
                    <Stage6 trauma={this.state.trauma} detail={this.state.detail} sensory={this.state.sensory} physical={this.state.physical} emotional={this.state.emotional} negative={this.state.negative} handleNext={this.handleNext} />
                }
                {this.state.stage === 10 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 11 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 12 &&
                    <Stage7 handleNext={this.handleNext} />
                }
                {this.state.stage === 13 &&
                    <Stage8 handlePositiveSet={this.handlePositiveSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 14 &&
                    <Stage9 positive={this.state.positive} handlePosScore1Set={this.handlePosScore1Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 15 &&
                    <Stage10 positive={this.state.positive} handleNext={this.handleNext} />
                }
                {this.state.stage === 16 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 17 &&
                    <Stage7 handleNext={this.handleNext} />
                }
                {this.state.stage === 18 &&
                    <Stage11 positive={this.state.positive} handlePosScore2Set={this.handlePosScore2Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 19 &&
                    <Stage12 handleNext={this.handleNext} />
                }
                {this.state.stage === 20 &&
                    <Breathwork handleNext={this.handleNext} />
                }
                {this.state.stage === 21 &&
                    <Stage13 trauma={this.state.trauma} handleScore2Set={this.handleScore2Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 22 &&
                    <Results score1={this.state.score1} score2={this.state.score2} posScore1={this.state.posScore1} posScore2={this.state.posScore2} />
                }
            </div>
        );
    };
}

export default PTSD;