import React from 'react';
import Accordion from 'react-bootstrap/Accordion';


function SevenEleven(props) {
    return (
            
            <Accordion style={{width: '80%', margin:'10px'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p style={{fontWeight: 'bold'}}>Noticing Visual Details</p>
                            <p style={{color: 'gray'}}> A technique for social anxiety</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{padding: '20px'}}>
                        When you are feeling anxious in a social situation, try to notice visual details in your environment such as the clothing people are wearing. What color shoes are they wearing? What the patterns and colors are they wearing? This will shift your brain out of anxiety-mode so you can socialize more comfortably.
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        
    );
}

export default SevenEleven;