function Help() {
    return (
      <div>
          <h1>How do I use Nurosync?</h1>
          <h3>Set aside 15 minutes of distraction-free time. Wear headphones or earbuds with the sound turned on. If you are using a phone or tablet, turn your device sideways.</h3>
          <h3>Take time to relax and ground yourself before and after using Nurosync before moving on to other activites.</h3>
          <h3>Make sure you are getting plenty of good sleep when using Nurosync.</h3>

          <h1>What is a target?</h1>
          <h3>A target can be any unwanted feeling, emotion, thought or memory that is causing you distress.</h3>
          <h3>By using Nurosync, your target will become less distressing to you and the underlying negative beliefs can be replaced by positive beliefs. </h3>

          <h1>How do I select a target?</h1>
          <h3>To select a target think of what drove you to use the app today. If there is anything bothering you, make that your target.</h3>
          <h3>If your target is a recurring emotion or feeling, try to think of a specfic event that stands out as the first or worst time you felt this way.</h3>
          <h3>You can be as detailed or as vague as you like about your target.</h3>
          <h3>A target can also be a future event that you are anxious about such as an upcoming presentation or plane trip.</h3>

          <h1>How often should I use Nurosync?</h1>
          <h3>Use Nurosync everyday or whenever you feel distressed, upset or depressed. A maximum of three sessions per day is recommended.</h3>

          <h1>Is Nurosync private?</h1>
          <h3>We take your privacy seriously. We do not share any of your data with any third party.  For more information, please read our <a href="/termsandconditions">Terms and Conditions agreement</a>.</h3>
          <h3>Everything you write during your sessions is between you and yourself: we do not store, save or share any written information you submit during your sessions. We do save the numerical scores (scale of 1 to 10) you submit during your sessions. You can see these scores and track your progress on the results page.</h3>

          <h1>Is Nurosync safe?</h1>
          <h3>Yes. Nurosync works by activating the healing and reprocessing abilities that your brain already has, so Nurosync is 100% natural and safe to use.</h3>
          <h3>People with pre-existing mental conditions who are undergoing a treatment plan should consult their therapist, physician or other health professional before using Nurosync. For more information please read our <a href="/termsandconditions">Terms and Conditions agreement</a>.</h3>
      </div>
    );
  }
  
  export default Help;