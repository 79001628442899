import React from 'react';
import { useState } from 'react';
import {
	FaAngleRight,
	FaAngleLeft, 
	FaChartBar, 
	FaThLarge, 
	FaTools, 
	FaCog,
	FaSignOutAlt,
	FaBars,
	FaEye,
} from 'react-icons/fa';
import { RiPsychotherapyFill } from 'react-icons/ri';
import { NavLink } from "react-router-dom";

import "../../navbar.css";
import EMDR from '../EMDR';
import GeneralEMDRResults from '../results/GeneralEMDRResults';
import RAIN from '../tools/RAIN';
import OneThreeTen from '../tools/OneThreeTen';
import LovingKindness from '../tools/LovingKindness';
import GeneralDashboardView from '../dashboardViews/GeneralDashboardView';
import GoalsWorksheet from '../tools/GoalsWorksheet';

const ICON_SIZE = 20;

const dashboard = <h1>Coming Soon!</h1>
const tools = <h1>Coming Soon!</h1>
const results = <h1>Coming Soon!</h1>


function Navbar() {
	const [activeComponent, setComponent] = useState(<EMDR />);
	const [navVisible, showNavbar] = useState(window.innerWidth > 600);
	let visible = navVisible 
	let show = showNavbar

	const showComponent = (component) => {
		setComponent(component)
	}

	return (

		

		<div className='dashboard'>

			<div className="mobile-side">
				<button
					className="mobile-side-btn"
					onClick={() => show(!visible)}
				>
					<FaBars size={24}  />
				</button>
			</div>
			<nav id="side-drawer" className={!visible ? 'sidebar' : ''}>
				<button
					type="button"
					className="side-btn"
					onClick={() => show(!visible)}
				>
					{ !visible
						? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
				</button>
				<div>

					<div className="links side-top">
						<div  onClick={() => showComponent(<div className="dashboardView"><GeneralDashboardView showComponent={showComponent}/></div>)}className="side-link">
							<FaThLarge size={ICON_SIZE} />
							<span>Dashboard</span>
						</div>
						<div onClick={() => showComponent(<EMDR />)} className="side-link">
							<FaEye size={ICON_SIZE} />
							<span>EMDR</span>
						</div>
						<div onClick={() => showComponent(<div className='dashboardView'><GoalsWorksheet/><RAIN /><OneThreeTen /><LovingKindness /></div>)} className="side-link">
							<FaTools size={ICON_SIZE} />
							<span>Tools</span> 
						</div>
						<div onClick={() => showComponent(<GeneralEMDRResults />)} className="side-link">
							<FaChartBar size={ICON_SIZE} />
							<span>Results </span>
						</div>

					</div>
				</div>
			</nav>
			<div id="main" className={!navVisible ? "page" : "page page-with-navbar"}>
				<div id="scroller" className='dashboardView'>
					{activeComponent}
				</div>
			</div>
		</div>
  );
}

export default Navbar;
