import React, { Component } from "react";
import '../App.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


class Stage10 extends Component {

    constructor(props) {
        super(props);
        this.state = ({ selection : null });
        this.handleClick.bind(this);
    }

    handleClick = () => {
        this.props.handleNext();
    }

    



    render () {

        return (
            <div className="stage1">
                <h1>Make a list of three things you are grateful for.</h1>
                <Form className="listInput">
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label className="formLabel" column sm="1">
                        1.
                        </Form.Label>
                        <Col sm="10">
                        <Form.Control type="text" placeholder="..." />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label className="formLabel" column sm="1">
                        2.
                        </Form.Label>
                        <Col sm="10">
                        <Form.Control type="text" placeholder="..." />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label className="formLabel" column sm="1">
                        3.
                        </Form.Label >
                        <Col sm="10">
                        <Form.Control type="text" placeholder="..." />
                        </Col>
                    </Form.Group>
                </Form>
                <div className="start startButton">
                        <Button className="start" onClick={this.handleClick}>Next</Button>
                </div>
            </div>

        )
    }

}

export default Stage10;