import React from 'react';
import Button from 'react-bootstrap/Button';

function NotSubscribed(props) {

    const handleActivate = () => {
        window.location.href = '/pro';
    }

    return (
        <div>
            <h1 style={{textAlign:'center'}}>You must have an active subscription to use this feature.</h1>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Button style={{padding: '10px'}} onClick={handleActivate}>Start free trial</Button>
            </div>
        </div>
    );
}

export default NotSubscribed;