import React, { Component } from "react";
import '../../../App.css';
import Button from 'react-bootstrap/Button';

class Stage12 extends Component {

    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        this.props.handleNext();
    }

    render () {

        return (
            <div className="stage1">
                <h1>Next we will do a short breathing exercise. Try to syncronize your breathing with the blue dot. Inhale quietly through your nose. Exhale through your mouth with a wooshing sound.</h1>
                <div className="start startButton">
                    <Button className="start" onClick={this.handleClick}>Begin</Button>
                </div>
            </div>

        )
    }

}

export default Stage12;