import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from 'recharts';

function ResultsBarChart(props) {

    function getColorOfBar(score) {
        if(score["score"] <= 4) {
            return '#60e038';
        } if (score["score"] <= 9) {
            return '#e0d538';
        } if (score["score"] <= 14) {
            return '#e07e38'
        } if (score["score"] <= 19) {
            return '#e05238' 
        } if (score["score"] > 19) {
            return '#e03838'
        }
    }

    function getLabel(score) {
        if(score <= 4) {
            return 'Minimal depression';
        } if (score <= 9) {
            return 'Mild depression';
        } if (score <= 14) {
            return 'Moderate depression'
        } if (score <= 19) {
            return 'Moderately severe depression' 
        } if (score > 19) {
            return 'Severe depression'
        }
    }

    function getDate(data) {
        return data['payload']['date']
    }

    function CustomTooltip({ payload, label, active }) {
        if (active) {
          return (
            <div style={{backgroundColor: 'white', padding: '10px'}}>
              <p>{`Score: ${payload[0].value}`}</p>
              <p>{getLabel(payload[0].value)}</p>
              <p>{getDate(payload[0])}</p>
            </div>
          );
        }
      
        return null;
    }

    return (
        <div>
            <BarChart width={600} height={300} data={props.data}>
                <XAxis dataKey="date" />
                <YAxis dataKey="score" domain={props.domain} />
                <Tooltip content={<CustomTooltip />}/>
                {/* <Bar dataKey="score" barSize={30} fill="#C473D6"/> */}
                <Bar dataKey="score">
                    {
                        props.data.map((entry, index) => (
                            <Cell fill={getColorOfBar(entry)} strokeWidth={index === 2 ? 4 : 1}/>
                        ))
                    }
                 </Bar>
            </BarChart>

        </div>
    );
}

export default ResultsBarChart;