import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import { FaHeadphones, FaVolumeUp } from 'react-icons/fa';
import { BsFillPhoneLandscapeFill } from 'react-icons/bs';

import '../../../App.css';

class Stage10 extends Component {

    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        this.props.handleNext();
    }

    render () {

        return (
            <div className="stage1">
                <div style={{textAlign: 'center'}}>                
                    <FaVolumeUp size={80} style={{color: 'darkSlateGray', padding: '10px', margin: '10px'}} />
                    <FaHeadphones size={80} style={{color: 'darkSlateGray', padding: '10px', margin: '10px'}} />
                    <BsFillPhoneLandscapeFill size={80} style={{color: 'darkSlateGray', padding: '10px', margin: '10px'}} />
                </div>
                <h1>Think about your positive beliefs as you focus your eyes on the blue dot on the next stage. Wear headphones.</h1>
                <div className="targetContainer">
                    <div className="target">{this.props.positive}</div>
                </div>

                <div className="start startButton">
                        <Button className="start" onClick={this.handleClick}>Next</Button>
                </div>
            </div>

        )
    }

}

export default Stage10;