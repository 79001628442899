import React, { Component } from "react";
import '../App.css';
import Button from 'react-bootstrap/Button';

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Stage13 extends Component {

    constructor(props) {
        super(props);

        var canControlList = this.props.canControl.map((item) => <div><div className="controlItem"> <FontAwesomeIcon icon={faXmark} className="controlItemIconGreen"/>{item}</div></div>)
        
        var cantControlList = this.props.cantControl.map((item, index) => <div key={index + "del"}><div className="controlItem"> <FontAwesomeIcon onClick={() => this.handleDelete({index})} icon={faXmark} className="controlItemIcon" />{item}</div></div>) 
        
        var controlList = canControlList.concat(cantControlList).sort(() => Math.random() - 0.5);
        
        this.state = {
            controlList: controlList,
            cantControlCount: this.props.cantControl.length
        }
        this.handleClick.bind(this);
    }

    handleClick = () => {
        this.props.handleNext();
    }
    
    handleDelete = (id) => {
        let newList = this.state.controlList

       newList = newList.filter(item => item.key !== (id.index + "del"));
        // for(let i = 0; i < newList.length; i++) {
        //     // console.log(newList[i].key
            
        //     if(newList[i].key === (id.index + "del")) {
        //         console.log(true)
        //     };
        // }
        this.setState({ controlList: newList });
        this.setState({ cantControlCount: this.state.cantControlCount - 1});
    }



    render () {

        return (
            <div className="stage1">
                <h1>Delete all the things you can't control.</h1>
                <div className="controlList">
                    {this.state.controlList}
                </div>
                <div className="start startButton">
                        <Button disabled={this.state.cantControlCount > 0} className="start" onClick={this.handleClick}>Next</Button>
                </div>
            </div>

        )
    }

}

export default Stage13;