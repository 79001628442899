import React from 'react';
import Button from 'react-bootstrap/Button';

const handleClick = () => {
    window.open('https://apps.apple.com/us/app/nurosync/id6443683218', '_blank', 'noopener,noreferrer');
}

function Mobile(props) {
    return (
        <div>
            <h1>Nurosync is now on the App Store!</h1>
            <div>
                <Button style={{display: 'block', marginLeft: 'auto', marginRight: 'auto' }} onClick={handleClick}>View In App Store</Button>
            </div>
        </div>
    );
}

export default Mobile;