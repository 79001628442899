import React, { Component } from "react";
import '../App.css';

import Stage0 from "./Stage0";
import Stage1 from "./Stage1";
import Stage1_1 from "./Stage1_1";
import Stage1_2 from "./Stage1_2";
import Stage2 from "./Stage2";
import Stage3 from "./Stage3";
import Stage3_1 from "./Stage3_1"
import Stage4 from "./Stage4";
import Stage4_1 from "./Stage4_1";
import Stage4_2 from "./Stage4_2";
import Stage4_3 from "./Stage4_3";
import Stage4_4 from "./Stage4_4";
import Stage5 from "./Stage5";
import Stage5_1 from "./Stage5_1";
import Stage5_2 from "./Stage5_2";
import Stage6 from "./Stage6";
import Stage7 from "./Stage7";
import Stage8 from "./Stage8";
import Stage9 from "./Stage9";
import Stage10 from "./Stage10";
import Stage11 from "./Stage11";
import Stage12 from "./Stage12";
import Stage13 from "./Stage13";
import EndScreen from "./EndScreen";
import BilateralStimulation from "./BilateralStimulation";   


class EMDR extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stage: 0,
            target: null,
            detail: null,
            pos: null,
            moodScore1: null,
            moodScore2: null,
            targetScore1: null,
            targetScore2: null,
            posScore1: null,
            posScore2: null,
            canControl: [],
            cantControl: []
        };
        this.handlePosSet.bind(this);
        this.handlePosScoreSet.bind(this);
        this.handlePosScore2Set.bind(this);
        this.handleTargetSet.bind(this);
        this.handleDetailSet.bind(this);
        this.handleTargetScoreSet.bind(this);
        this.handleTargetScore2Set.bind(this);
        this.handleCanControlSet.bind(this);
        this.handleCantControlSet.bind(this);
        this.handleNext.bind(this);
    }

    
    handleNext = () => {
        window.scrollTo(0,100);
        this.setState({ stage: this.state.stage + 1 });
    }
    handleSkip = () => {
        this.setState({ stage: 15 });
    }
    handleMoodScoreSet = (newScore) => {
        this.setState({ moodScore1: newScore })
    }
    handleMoodScore2Set = (newScore) => {
        this.setState({ moodScore2: newScore })
    }

    handleTargetSet = (newTarget) => {
        this.setState({ target: newTarget });
    }

    handleDetailSet = (newDetail) => {
        this.setState({ detail: newDetail });
    }

    handlePosSet = (newPos) => {
        this.setState({ pos: newPos });
    }

    handleReset = () => {
        this.setState({ stage: 0 });
    }

    handleTargetScoreSet = (newScore) => {
        this.setState({ targetScore1: newScore });
    }

    handleTargetScore2Set = (newScore) => {
        this.setState({ targetScore2: newScore });
    }

    handlePosScoreSet = (newScore) => {
        this.setState({ posScore1: newScore });
    }
    handlePosScore2Set = (newScore) => {
        this.setState({ posScore2: newScore });
    }

    handleCanControlSet = (newList) => {
        this.setState({ canControl: newList })
    }

    handleCantControlSet = (newList) => {
        this.setState({ cantControl: newList })
    }

    componentDidMount = () => {
        
    }

    render() {

        return (
            <div className="emdr">
                {this.state.stage === 0 &&
                    <Stage0 handleNext={this.handleNext} />
                }

                {this.state.stage === 1 &&
                    <Stage1 handleNext={this.handleNext} />
                }

                {this.state.stage === 2 &&
                    <Stage1_1 handleScoreSet={this.handleMoodScoreSet} handleNext={this.handleNext} />
                }

                {this.state.stage === 3 &&
                    <Stage1_2 handleNext={this.handleNext} handleSkip={this.handleSkip} />
                }

                {this.state.stage === 4 &&
                    <Stage2 handleTargetSet={this.handleTargetSet} handleNext={this.handleNext} />
                }

                {this.state.stage === 5 &&
                    <Stage3 target={this.state.target} handleScoreSet={this.handleTargetScoreSet} handleNext={this.handleNext} />
                }


                {this.state.stage === 6 &&
                    <Stage3_1 target={this.state.target} handleNext={this.handleNext} />
                }

                {this.state.stage === 7 &&
                    <BilateralStimulation stage={this.state.stage} handleNext={this.handleNext} />
                }

                {this.state.stage === 8 &&
                    <Stage4_1 handleDetailSet={this.handleDetailSet} handleNext={this.handleNext} />
                }

                {this.state.stage === 9 &&
                    <Stage4_2 detail={this.state.detail} handleNext={this.handleNext} />
                }

                {this.state.stage === 10 &&
                    <BilateralStimulation stage={this.state.stage} handleNext={this.handleNext} />
                }

                {this.state.stage === 11 &&
                    <Stage4_3 handleDetailSet={this.handleDetailSet} handleNext={this.handleNext} />
                }

                {this.state.stage === 12 &&
                    <Stage4_4 detail={this.state.detail} handleNext={this.handleNext} />
                }

                {this.state.stage === 13 &&
                    <BilateralStimulation stage={this.state.stage} handleNext={this.handleNext} />
                }

                {this.state.stage === 14 &&
                    <Stage3 target={this.state.target} handleScoreSet={this.handleTargetScore2Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 15 &&
                    <Stage5 handlePosSet={this.handlePosSet} handleNext={this.handleNext} />
                }

                {this.state.stage === 16 &&
                    <Stage5_1 pos={this.state.pos} handlePosScoreSet={this.handlePosScoreSet} handleNext={this.handleNext} />
                }

                {this.state.stage === 17 &&
                    <Stage5_2 pos={this.state.pos} handleNext={this.handleNext} />
                }

                {this.state.stage === 18 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }

                {this.state.stage === 19 &&
                    <Stage7 pos={this.state.pos} handleScoreSet={this.handlePosScore2Set} handleNext={this.handleNext} />
                }

                {this.state.stage === 20 &&
                    <Stage8 handleNext={this.handleNext} />
                }

                {this.state.stage === 21 &&
                    <Stage9 handleNext={this.handleNext} />
                }

                {this.state.stage === 22 &&
                    <Stage1_1 handleScoreSet={this.handleMoodScore2Set} handleNext={this.handleNext} />
                }

                {this.state.stage === 23 &&
                    <EndScreen
                        targetScore1={this.state.targetScore1}
                        targetScore2={this.state.targetScore2}
                        posScore1={this.state.posScore1}
                        posScore2={this.state.posScore2}
                        moodScore1={this.state.moodScore1}
                        moodScore2={this.state.moodScore2}
                    />
                }

            </div>
        )
    }

}

export default EMDR;