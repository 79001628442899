import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from 'recharts';

function AnxietyTestResultsBarChart(props) {
    console.log(props.data)
    function getColorOfBar(score) {

        if(score["score"] <= 4) {
            return '#60e038';
        } if(score["score"] <= 9 ) {
            return '#e0d538'
        } if (score["score"] <= 14) {
            return '#e07e38';
        } if (score["score"] >= 15) {
            return '#e03838';
        }
 
        
    }

    function getLabel(score) {
        if(score <= 4) {
            return 'Minimal anxiety';
        } if(score <= 9 ) {
            return 'Mild anxiety'
        } if (score <= 14) {
            return 'Moderate anxiety';
        } if (score >= 15) {
            return 'Severe anxiety';
        }
    }

    function getDate(data) {
        return data['payload']['date']
    }

    function CustomTooltip({ payload, label, active }) {
        if (active) {
          return (
            <div style={{backgroundColor: 'white', padding: '10px'}}>
              <p>{`Score: ${payload[0].value}`}</p>
              <p>{getLabel(payload[0].value)}</p>
              <p>{getDate(payload[0])}</p>
            </div>
          );
        }
      
        return null;
    }

    return (
        <div>
            <BarChart width={600} height={300} data={props.data}>
                <XAxis dataKey="date" />
                <YAxis dataKey="score" domain={props.domain} />
                <Tooltip content={<CustomTooltip />}/>
                {/* <Bar dataKey="score" barSize={30} fill="#C473D6"/> */}
                <Bar dataKey="score">
                    {
                        props.data.map((entry, index) => (
                            <Cell fill={getColorOfBar(entry)} strokeWidth={index === 2 ? 4 : 1}/>
                        ))
                    }
                 </Bar>
            </BarChart>

        </div>
    );
}

export default AnxietyTestResultsBarChart;