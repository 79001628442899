function About() {
    return (
      <div>
          <h1>About Nurosync</h1>
          <h3>Nurosync is an online mental health tool designed to help people who are struggling with anxiety, recurring negative thoughts, trauma, PTSD, depression and other mental health issues. Nurosync can be used by anyone with an internet connected device.</h3>
          <h3>Nurosync is more than just a mood booster app. Nurosync is designed to be quick and easy to use, yet it is a powerful tool for healing trauma and other mental issues.</h3>
          <h3>Nurosync enables your brain to reprocess information and heal itself so it can run smoother, kind of like reformatting a hardrive. Defrag your brain and eliminate negative thought patterns so you can get back to living your best life.</h3>
          <h1>How it works</h1>
          <h3>Nurosync uses an integrated series of therapy exercises and procedures to give you immediate and lasting improvement in your mental health and overall well-being.</h3>
          <h3>Cutting edge therapy techniques like EMDR are deployed to promote lasting mental healing that can improve the your quality of life drastically.</h3>
          <h1>About EMDR</h1>
          <h3>Eye Movement Desensitization and Reprocessing, also known as EMDR, is a cornerstone of Nurosync's therapy program. This form of therapy uses bilateral stimulation to help your brain reprocess trauma and heal itself naturally. Bilateral stimulation is simply stimulating the left (logical) side and right (creative) side of your brain to get them working together in sync.</h3>
          <h3>Trauma causes a fight or flight response in the primitive reptilian brain. This automatic response shuts down higher-level regions of the brain, preventing the trauma from being processed. In this way, trauma can get "stuck" in the lower reptilian brain causing recurring issues. Bilateral stimulation activates the entire brain so that the brain's natural healing process can take place.</h3>
      </div>
    );
  }
  
  export default About;