import React, { Component } from "react";
import '../../../App.css';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from "react-bootstrap/esm/ButtonToolbar";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

class Stage3 extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            response: null
        })
        this.handleClick.bind(this);
    }
    handleChange = (newResponse) => {
        this.setState({response: newResponse});
    }
    handleClick = () => {
        if(this.state.response === "no") {
            this.props.handleNext();
        } else if(this.state.response === "yes") {
            this.props.handleModeSelect("past");
            this.props.handleSkip(5);
        } else {
            this.props.handleNext();
        }

    }

    render () {

        return (
            <div className="stage1">
                <h1>Is this depression linked to specific events in your past?</h1>
                <div className="numberSelect">
                    <ButtonToolbar aria-label="Toolbar with button groups" className="buttonToolbar">
                        <ButtonGroup className="me-2" aria-label="First group">
                            <Button style={{width: "70px"}} variant={this.state.response === 'yes' ? "primary": "secondary"} onClick={() => this.handleChange("yes")}>Yes</Button>
                            <Button style={{width: "70px"}} variant={this.state.response === 'no' ? "primary": "secondary"} onClick={() => this.handleChange("no")}>No</Button> 
                        </ButtonGroup>
                    </ButtonToolbar>
                </div>
                <div className="start startButton">
                        <Button className="start" onClick={this.handleClick}>Next</Button>
                </div>
            </div>

        )
    }

}

export default Stage3;