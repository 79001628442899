import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import EMDR from '../EMDR';
import PTSD from '../protocols/PTSD';

function TraumaDashboardView(props) {

    const setComponent = () => {
        props.showComponent(<PTSD />)
    }

    return (
        <div>
            <h1>Dashboard</h1>
            <Card style={{margin: '10px', maxWidth: '500px', maxHeight: '280px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
                <Card.Body>
                    <Card.Title>Recommended for you</Card.Title>
                    <div style={{padding: '10px'}}>
                        <Card.Subtitle className="mb-2 text-muted">EMDR Trauma Protocol</Card.Subtitle>
                        <Card.Text>
                            Try the Eye Movement Desensitizing and Reprocessing general protocol for trauma/PTSD.
                        </Card.Text>
                        <Button variant="primary" onClick={() => setComponent()}>Start</Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default TraumaDashboardView;