import React, { Component } from "react";
import '../App.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


class Stage12 extends Component {

    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        let cantControl = [];
        if(document.getElementById("textInput1").value !== ""){cantControl.push(document.getElementById("textInput1").value)};
        if(document.getElementById("textInput2").value !== ""){cantControl.push(document.getElementById("textInput2").value)};
        if(document.getElementById("textInput3").value !== ""){cantControl.push(document.getElementById("textInput3").value)};
        this.props.handleCantControlSet(cantControl);
        this.props.handleNext();
    }



    render () {

        return (
            <div className="stage1">
                <h1>Make a list of three things in your life you can't control.</h1>
                <Form className="listInput">
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label className="formLabel" column sm="1">
                        1.
                        </Form.Label>
                        <Col sm="10">
                        <Form.Control id="textInput1" type="text" placeholder="..." />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label className="formLabel" column sm="1">
                        2.
                        </Form.Label>
                        <Col sm="10">
                        <Form.Control id="textInput2" type="text" placeholder="..." />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label className="formLabel" column sm="1">
                        3.
                        </Form.Label >
                        <Col sm="10">
                        <Form.Control id="textInput3" type="text" placeholder="..." />
                        </Col>
                    </Form.Group>
                </Form>
                <div className="start startButton">
                        <Button className="start" onClick={this.handleClick}>Next</Button>
                </div>
            </div>

        )
    }

}

export default Stage12;