import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/esm/Button';
import BreathDot from './BreathDot';


function GuidedBreathing(props) {


    const showComponent = () => {
        console.log("HEY")
        props.showComponent(<BreathDot showComponent={props.showComponent} />)
    }


    return (
            
            <Accordion style={{width: '80%', margin:'10px'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p style={{fontWeight: 'bold'}}>Guided Breathing Exercise</p>
                            <p style={{color: 'gray'}}> Follow the breath guide to reduce stress and anxiety and heal from and trauma </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{fontStyle:'italic'}}>Breath in sync with the blue dot. Inhale through the nose, exhale through the mouth.</p>
                        <Button onClick={showComponent} variant="primary">
                            Start
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        
    );
}

export default GuidedBreathing;