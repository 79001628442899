import React from 'react';

import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';


function EMDRResultsChart(props) {
    return (
        <div>
            <BarChart width={400} height={400} data={props.data} style={{margin: "auto"}}>
                <XAxis dataKey="date"/>
                <YAxis domain={[0, 10]}/>
                <Tooltip />
                <Bar dataKey={props.dataKey1} barSize={30} name="Before" fill="#8884d8"/>
                <Bar dataKey={props.dataKey2} barSize={30} name="After" fill="#82ca9d"/>

            </BarChart>
        </div>
    );
}

export default EMDRResultsChart;