import React, { Component } from "react";
import '../App.css';

class Stage9 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breathText: ["Inhale", "Hold", "Exhale"],
            breath: 0,
            conditionalClass : "breathText"
        };
    }


    nextStage = () => {
        this.props.handleNext();
    }

    handleBreathChange = () => {
        setTimeout(this.incrementBreath, 4000);
        setTimeout(this.incrementBreath, 11000);
        setTimeout(this.incrementBreath, 19000);
        setTimeout(this.handleBreathChange, 19000);
    }

    incrementBreath = () => {
        if(this.state.breath === 2) {
            this.setState({breath: 0});
        } else  {
            this.setState({breath: this.state.breath + 1})
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.handleBreathChange();
        setTimeout(this.nextStage, 76000);
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render () {

        return (
            <div className="stage1">
                <div className="breathContainer">
                    <div className="breathDot"></div>
                    <div className={`breathText ${this.state.breath === 1 ? 'flash' : ''}`}>{this.state.breathText[this.state.breath]}</div>

                </div>

            </div>

        )
    }

}

export default Stage9;