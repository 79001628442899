import React, { Component } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import app from "../../firebase"
import Chart from '../Chart';

//TODO: Put DB in firebase.js and export it
const db = getFirestore(app);
const auth = getAuth();


class ResultsChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            charts: [],
            header: [],
        }
    }

    getData = async (userId) => {
        const docRef = doc(db, "generalEMDRResults", userId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            let data = docSnap.data().scores;
            this.renderCharts(data);

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    renderCharts(data) {
        const moodChart = <Chart data={data} dataKey1="moodScore1" dataKey2="moodScore2"/>
        const targetChart = <Chart data={data} dataKey1="targetScore1" dataKey2="targetScore2"/>
        const posChart = <Chart data={data} dataKey1="posScore1" dataKey2="posScore2"/>
        let charts = [<h3 style={{textAlign: "center"}}>Mood Score</h3>, moodChart, <h3 style={{textAlign: "center"}}>Target Score</h3>, targetChart, <h3 style={{textAlign: "center"}}>Positive Score</h3>, posChart];
        this.setState({ header: [<h1>General EMDR Results</h1>] })
        this.setState({ charts: charts });

    }


    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            let uid = user.uid;
            this.getData(uid);

            // ...
            } else {
            // User is signed out
            // ...
            // this.renderChartsHidden()
                this.setState({ header: [<h1>You must be signed in to use this feature.</h1>] })

            }
        });

    }

    render() {

        return (
            <div>
                <div style={{display: "block"}} id="children-pane">
                    {this.state.header}                    
                    {this.state.charts}
                </div>

            </div>

        );
    }

}

export default ResultsChart;