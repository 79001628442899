import React, { Component } from "react";
import '../App.css';
import Button from 'react-bootstrap/Button';
import { FaHeadphones, FaVolumeUp } from 'react-icons/fa';
import { BsFillPhoneLandscapeFill } from 'react-icons/bs';

class Stage5_2 extends Component {

    constructor(props) {
        super(props);
        this.handleClick.bind(this);
    }

    handleClick = () => {
        this.props.handleNext();
    }

    render () {

        return (
            <div className="stage1" >
                <div style={{textAlign: 'center'}}>                
                    <FaVolumeUp size={80} style={{color: 'darkSlateGray', padding: '10px', margin: '10px'}} />
                    <FaHeadphones size={80} style={{color: 'darkSlateGray', padding: '10px', margin: '10px'}} />
                    <BsFillPhoneLandscapeFill size={80} style={{color: 'darkSlateGray', padding: '10px', margin: '10px'}} />
                </div>

                <h1>Hold your your positive beliefs in your mind while you follow the blue dot with your eyes.</h1>
                <div className="targetContainer">
                    <div className="target">{this.props.pos}</div>
                </div>
                <div className="start startButton">
                    <Button className="start" onClick={this.handleClick}>Start EMDR</Button>
                </div>
            </div>

        )
    }

}

export default Stage5_2;