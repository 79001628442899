import React from 'react';
import Accordion from 'react-bootstrap/Accordion';


function BellyBreathing(props) {
    return (
            
            <Accordion style={{width: '80%', margin:'10px'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p style={{fontWeight: 'bold'}}>Belly Breathing</p>
                            <p style={{color: 'gray'}}> A breathwork practice for trauma</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{padding: '20px'}}>
                            <p style={{fontStyle: 'italic'}}>When you feel stressed or triggered try this breathing technique.</p>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>1 - Place one hand on your upper chest and the other hand on your belly, below the ribcage.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>2 - Allow your belly to relax, without forcing it inward by squeezing or clenching your muscles.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>3 - Breathe in slowly through your nose. The air should move into your nose and downward so that you feel your stomach rise with your other hand and fall.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>4 - Exhale slowly through slightly pursed lips. Take note of the hand on your chest, which should remain relatively still.</p>

                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        
    );
}

export default BellyBreathing;