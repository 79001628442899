import React, { Component } from "react";

import '../../App.css';

import Stage0 from "./depressionStages/Stage0";
import Stage1 from "./depressionStages/Stage1";
import Stage2 from "./depressionStages/Stage2";
import Stage3 from "./depressionStages/Stage3";
import Stage4 from "./depressionStages/Stage4";
import Stage5a from "./depressionStages/Stage5a";
import Stage5b from "./depressionStages/Stage5b";
import Stage6 from "./depressionStages/Stage6";
import Stage7 from "./depressionStages/Stage7";
import Stage8 from "./depressionStages/Stage8";
import Stage9 from "./depressionStages/Stage9";
import Stage10 from "./depressionStages/Stage10";
import Stage11 from "./depressionStages/Stage11";
import Stage12 from "./depressionStages/Stage12";
import Stage13 from "./depressionStages/Stage13";
import Stage14 from "./depressionStages/Stage14";
import Stage15 from "./depressionStages/Stage15";
import Stage16 from "./depressionStages/Stage16";
import Stage17 from "./depressionStages/Stage17";
import Results from "./depressionStages/Results";
import BilateralStimulation from "../BilateralStimulation";
import Breathwork from "../Breathwork";

class Depression extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 0,
            score1: null,
            score2: null,
            mode: null,
            sensory: null,
            physical: null,
            emotional: null,
            negBelief: null,
            positive: null,
            future: null,
        };
    };

    handleNext = () => {
        document.getElementById("scroller").scroll(0,0)
        this.setState({ stage: this.state.stage + 1 });
    }
    handleSkip = (nextStage) => {
        this.setState({ stage: nextStage});
    }
    handleModeSelect = (newSelection) => {
        this.setState({ mode: newSelection });
    }
    handleScore1Set = (newScore) => {
        this.setState({ score1: newScore });
    }
    handleScore2Set = (newScore) => {
        this.setState({ score2: newScore });
    }
    handleTargetSet = (newTarget) => {
        this.setState({ target: newTarget });
    }
    handleSensorySet = (newSensory) => {
        this.setState({ sensory: newSensory });
    }
    handleEmotionalSet = (newEmotional) => {
        this.setState({ emotional: newEmotional });
    }
    handlePhysicalSet = (newPhysical) => {
        this.setState({ physical: newPhysical });
    }
    handleNegBeliefSet = (newBelief) => {
        this.setState({ negBelief: newBelief });
    }
    handlePositiveSet = (newPositive) => {
        this.setState({ positive: newPositive });
    }
    handleFutureSet = (newFuture) => {
        this.setState({ future: newFuture });
    }

    

    render() {
        return (
            <div className="emdr">
                {this.state.stage === 0 &&
                    <Stage0 handleNext={this.handleNext} />
                }
                {this.state.stage === 1 &&
                    <Stage1 handleNext={this.handleNext} />
                }
                {this.state.stage === 2 &&
                    <Stage2 handleScore1Set={this.handleScore1Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 3 &&
                    <Stage3 handleSkip={this.handleSkip} handleModeSelect={this.handleModeSelect} handleNext={this.handleNext} />
                }
                {this.state.stage === 4 &&
                    <Stage4 handleSkip={this.handleSkip} handleModeSelect={this.handleModeSelect} handleNext={this.handleNext} />
                }
                {(this.state.stage === 5 && this.state.mode === 'past') &&
                    <Stage5a handleNext={this.handleNext} handleTargetSet={this.handleTargetSet} />
                }                
                {(this.state.stage === 5 && this.state.mode === 'present') &&
                    <Stage5b handleNext={this.handleNext} handleTargetSet={this.handleTargetSet} />
                }
                {this.state.stage === 6 &&
                    <Stage6 target={this.state.target} handleSensorySet={this.handleSensorySet} handleNext={this.handleNext} />
                }
                {this.state.stage === 7 &&
                    <Stage7 target={this.state.target} handleEmotionalSet={this.handleEmotionalSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 8 &&
                    <Stage8 target={this.state.target} handlePhysicalSet={this.handlePhysicalSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 9 &&
                    <Stage9 target={this.state.target} sensory={this.state.sensory} emotional={this.state.emotional} physical={this.state.physical} handleNegBeliefSet={this.handleNegBeliefSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 10 &&
                    <Stage10 target={this.state.target} sensory={this.state.sensory} emotional={this.state.emotional} physical={this.state.physical} negBelief={this.state.negBelief} handleNext={this.handleNext} />
                }
                {this.state.stage === 11 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 12 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 13 &&
                    <Stage11 handleNext={this.handleNext} />
                }
                {this.state.stage === 14 &&
                    <Stage12 handleNext={this.handleNext} />
                }
                {this.state.stage === 15 &&
                    <Breathwork handleNext={this.handleNext} />
                }
                {this.state.stage === 16 &&
                    <Stage13 handlePositiveSet={this.handlePositiveSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 17 &&
                    <Stage14 positive={this.state.positive} handleNext={this.handleNext} />
                }
                {this.state.stage === 18 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 19 &&
                    <Stage15 handleFutureSet={this.handleFutureSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 20 &&
                    <Stage16 future={this.state.future} handleNext={this.handleNext} />
                }
                {this.state.stage === 21 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 22 &&
                    <Stage11 handleNext={this.handleNext} />
                }
                {this.state.stage === 23 &&
                    <Stage17 handleScore2Set={this.handleScore2Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 24 &&
                    <Results score1={this.state.score1} score2={this.state.score2} />
                }



            </div>
        );
    };
}

export default Depression;