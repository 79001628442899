import React from 'react';
import Accordion from 'react-bootstrap/Accordion';


function RAIN(props) {
    return (
            
            <Accordion style={{width: '80%', margin:'10px'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p style={{fontWeight: 'bold'}}>R.A.I.N </p>
                            <p style={{color: 'gray'}}> A technique for handling difficult emotions</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{padding: '20px'}}>
                            <p> Difficult emotions are hard to control. Sometimes when we try to supress them it can make them more intense and challenging for us. This technique is effective because it uses the opposite approach: letting the emotions run their course like a wave in the ocean. When you begin to feel difficult emotions, use this four-step process:</p>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>R - Recognize you are begining to feel intense emotions.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>A - Allow yourself to feel these emotions. Just ride out the wave of emotion without doing anything about it yet.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>I - Investigate body sensations, thinking and feelings associated with the intense emotion(s).</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>N - Nurture yourself with self-soothing, self-calming talk and self-compassion.</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        
    );
}

export default RAIN;