import React, { Component } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, doc, addDoc , query, where, setDoc, getDoc } from "firebase/firestore";

import DepressionTestResultsBarChart from './DepressionTestResultsBarChart';
import app from "../../firebase"

const db = getFirestore(app);
const auth = getAuth();


class DepressionTestResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            component: [],
        }
    }


    renderChart = (data) => {
        this.setState({ component: [<DepressionTestResultsBarChart data={data} domain={[0, 27]} />] });
    }

    getData = async (userId) => {
        const docRef = doc(db, "depressionTest", userId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            let data = docSnap.data().scores;
            this.renderChart(data);

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    componentDidMount() {

        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
                let uid = user.uid;
                this.getData(uid);
                
                
            // ...
            } else {
            // User is signed out
            // ...

            }
        });
    }
    render() {
        return (
            <div>
                <h1>Depression Test Results</h1>
                {this.state.component}
            </div>
        );
    }

}

export default DepressionTestResults;