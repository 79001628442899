import React, { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';




function Contact() {

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_qybgvjb', 'template_27v06ya', e.target, 'G-smFimY8pKWgyVst')
          .then((result) => {
              // console.log(result.text);
          }, (error) => {
              // console.log(error.text);
          });
        e.target.reset();
    }


    return (
        <div class="container contact">
        <div class="row" style={{width: "500px"}}>
          <div class="col align-self-center">
            <h1 class="text-center">We love to hear from you!</h1>
            {/* <!-- contact form --> */}
            <form onSubmit={sendEmail} >
              {/* <!-- name --> */}
              <div class="form-group contactFormElement">
                <label for="name">Name</label>
                <input
                  type="name"
                  name="name"
                  class="form-control"
                  id="name"
                  placeholder="Enter your name"
                />
              </div>
  
              {/* <!-- email --> */}
              <div class="form-group contactFormElement">
                <label for="email">Email address</label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  id="email"
                  placeholder="Enter your email"
                />
              </div>
  
              <div class="form-group contactFormElement">
                <label for="email_body">Message</label>
                <textarea
                    name="message"
                  class="form-control"
                  id="email_body"
                  rows="5"
                ></textarea>
              </div>
  
              <button type="submit" class="btn btn-primary contactSubmitButton">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    )
}



export default Contact;