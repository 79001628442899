import React from 'react';
import Accordion from 'react-bootstrap/Accordion';


function OneThreeTen(props) {
    return (
            
            <Accordion style={{width: '80%', margin:'10px'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p style={{fontWeight: 'bold'}}>1-3-10</p>
                            <p style={{color: 'gray'}}> A technique for handling anger and stress</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{padding: '20px'}}>
                            <p>When you begin to notice stress or anger try this simple three step technique to get back into your best mind.</p>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>1 - Tell yourself to calm down. Notice that this is something that usually would make you uspet, stressed or angry.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>3 - Take three deep breaths. Try to breath into your belly. Exhale more than you inhale.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>10 - Count to ten. This gives us a break from thinking so we can find ourseslves.</p>

                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        
    );
}

export default OneThreeTen;