import React, { Component } from "react";
import '../../../App.css';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

class Stage17 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selection : null
        }
        this.handleClick.bind(this);
    }

    handleScoreSet = (newScore) => {
        this.setState({selection : newScore});
        this.props.handleScore2Set(newScore);
    }
    handleClick = () => {
        this.props.handleNext();
    }

    render () {

        return (
            <div className="stage1">
                <h1>On a scale of 1 to 10, how depressed do you feel now?</h1>
                <div className="numberSelect">
                    <ButtonToolbar aria-label="Toolbar with button groups" className="buttonToolbar">
                        <ButtonGroup className="me-2" aria-label="First group">
                            <Button variant={this.state.selection === 0 ? "primary": "secondary"} onClick={() => this.handleScoreSet(0)}>0</Button>
                            <Button variant={this.state.selection === 1 ? "primary": "secondary"} onClick={() => this.handleScoreSet(1)}>1</Button> 
                            <Button variant={this.state.selection === 2 ? "primary": "secondary"} onClick={() => this.handleScoreSet(2)}>2</Button>
                            <Button variant={this.state.selection === 3 ? "primary": "secondary"} onClick={() => this.handleScoreSet(3)}>3</Button>
                            <Button variant={this.state.selection === 4 ? "primary": "secondary"} onClick={() => this.handleScoreSet(4)}>4</Button>
                            <Button variant={this.state.selection === 5 ? "primary": "secondary"} onClick={() => this.handleScoreSet(5)}>5</Button>
                            <Button variant={this.state.selection === 6 ? "primary": "secondary"} onClick={() => this.handleScoreSet(6)}>6</Button>
                            <Button variant={this.state.selection === 7 ? "primary": "secondary"} onClick={() => this.handleScoreSet(7)}>7</Button>
                            <Button variant={this.state.selection === 8 ? "primary": "secondary"} onClick={() => this.handleScoreSet(8)}>8</Button>
                            <Button variant={this.state.selection === 9 ? "primary": "secondary"} onClick={() => this.handleScoreSet(9)}>9</Button>
                            <Button variant={this.state.selection === 10 ? "primary": "secondary"} onClick={() => this.handleScoreSet(10)}>10</Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </div>
                <div className="start startButton">
                        <Button className="start" onClick={this.handleClick}>Next</Button>
                </div>
            </div>

        )
    }

}

export default Stage17;