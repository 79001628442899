import React from 'react';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged} from 'firebase/auth';
import { getFirestore, doc, getDoc, } from "firebase/firestore";

import NotSignedIn from './NotSignedIn';
import NotVerified from './NotVerified';
import NotSubscribed from './NotSubscribed';
import app from "../firebase"

import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
  } from "firebase/functions";

const functions = getFunctions(app);
const auth = getAuth();
const db = getFirestore(app);

const ProtectedRoute = ({ children, ...rest }) => {
    const loadingGif = [
        <div style={{display: 'flex', height: 'calc(100vh - 100px)', justifyContent: 'center', alignItems: 'center'}}>
            <img style={{position: 'relative', width: '50px', height:'50px', bottom: '30px' }}src="https://media.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />
        </div>
    ];

    const [component, setComponent] = useState(loadingGif);
    const [user, setUser] = useState(null);
    const [verified, setVerified] = useState(null);
    const [subscription, setSubscription] = useState(null);


   const getSubscriptionStatus = async (uid) => {  
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if(docSnap.data().subscriptionId) {
            const sendText = httpsCallable(functions, 'getSubscriptionStatus')
            return sendText(docSnap.data().subscriptionId, 'more stuff')
            .then(response => {
                if(response.data.status === 'trialing' || response.data.status === 'active') {
                    // User has active subscription...
                    setComponent(children)
                } else {
                    // User has inactive subscription...
                    setComponent([<NotSubscribed />])
                }
            });
        } else {
            // No subscription id on user document...
            setComponent([<NotSubscribed />])

        }

    }
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if(currentUser) {
                //user is signed in...
                if(currentUser.emailVerified) {
                    getSubscriptionStatus(currentUser.uid);
                } else {
                    setComponent([<NotVerified />])
                }
            } else {
                //user is signed out...
                setComponent([<NotSignedIn />]);
            }

        })
        return() => {
            unsubscribe();
        }
    },[])

    return (
        <div>
            {component}
        </div>
    )

} 

export default ProtectedRoute;