import React from "react";
import { Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";

import Home2 from "./Home2";
import Home from "./Home";
import EMDR from "./EMDR"
import About from "./About";
import Help from "./Help";
import Contact from "./Contact";
import Mobile from "./Mobile";
import TermsAndConditions from "./TermsAndConditions";
import Signup from "./Signup";
import Signin from "./Signin";
import Account from "./Account";
import Subscribe from "./Subscribe";
import { AuthContextProvider } from "../context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Completion from "./Completion";
import Checkout from "./Checkout";
import ResetPassword from "./ResetPassword";
import AccountSuccess from "./AccountSuccess";
import SevenEleven from "./tools/SevenEleven";
import Dashboard from "./dashboards/Dashboard";
import AnxietyDashboard from "./dashboards/AnxietyDashboard";
import DepressionDashboard from "./dashboards/DepressionDashboard";
import TraumaDashboard from "./dashboards/TraumaDashboard";



function AppRouter() {
    const [navVisible, showNavbar] = useState(false);

    return (
                <Routes>
                    <Route path="/" element={
                        <Home />
                    } />
                    <Route path="/home2" element={
                        <Home2 />
                    } />
                    <Route path="/therapy" element={
                        <Dashboard visible={ navVisible } show={ showNavbar }/>
                    } />
                    <Route path="/anxiety" element={
                        <ProtectedRoute>
                            <AnxietyDashboard />
                        </ProtectedRoute>
                    } />
                    <Route path="/depression" element={
                        <ProtectedRoute>
                            <DepressionDashboard />
                        </ProtectedRoute>
                    } />
                    <Route path="/trauma" element={
                        <ProtectedRoute>
                            <TraumaDashboard />
                        </ProtectedRoute>
                    } />
                    <Route path="/about" element={
                        <About />
                    } />
                    <Route path="/mobile" element={
                        <Mobile />
                    } />
                    <Route path="/faq" element={
                        <Help />
                    } />
                    <Route path="/contact" element={
                        <Contact />
                    } />
                    <Route path="/termsandconditions" element={
                        <TermsAndConditions />
                    } />
                    <Route path="/signup" element={
                        <Signup />
                    } />
                    <Route path="/signin" element={
                        <Signin />
                    } />
                    <Route path="/account" element={
                        <Account />
                    } />
                    <Route path="/pro" element={
                            <Subscribe />
                    } />
                    <Route path="/completion" element={
                        <Completion />
                    } />
                    <Route path="/checkout" element={
                        <Checkout />
                    } />
                    <Route path="/reset" element={
                        <ResetPassword />
                    } />
                    <Route path="/accountcreated" element={
                        <AccountSuccess />
                    } />
                    <Route path="/test" element={
                        <SevenEleven />
                    } />
                </Routes>

    )
}

export default AppRouter;