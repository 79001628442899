import React from 'react';
import Button from 'react-bootstrap/Button';


function NotSignedIn(props) {
    const handleSignIn = () => {
        window.location.href = '/signin'
    }
    const handleCreateAccount = () => {
        window.location.href = '/signup'
    }

    return (
        <div>
            <h1 style={{ textAlign: "center" }}>You must be signed in to use this feature.</h1>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button style={{ margin: '10px' }} onClick={handleSignIn}>Sign in</Button>
                <Button style={{ margin: '10px' }} onClick={handleCreateAccount}>Create account</Button>
            </div>
        </div>
    );
}

export default NotSignedIn;