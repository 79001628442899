import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Card } from 'react-bootstrap';
import { UserAuth } from '../context/AuthContext';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence} from "firebase/auth";



export default function Signup(props) {
    const { signIn } = UserAuth();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('')
    const navigate = useNavigate();


    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing(true);
        setError('');
        try {
            await signIn(email, password);
            
            navigate('/account');
        } catch (e) {
            if(e.message == 'Firebase: Error (auth/user-not-found).') {
                setError('User not found.')
            } else if (e.message == 'Firebase: Error (auth/wrong-password).') {
                setError('Password is incorrect.')
            } else {
                setError(e.message);
            }
            setIsProcessing(false);
        }
    }



    return (
        <div className='container'>
         <div style={{width: "400px", padding: "20px"}}>
            <Card>
                <Card.Body>
                    <h3>Sign In</h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" required onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" required onChange={(e) => setPassword(e.target.value)}/>
                            <div className="w-100 mt-2">
                                Forgot password? <a href='/reset'>Reset password</a>
                            </div>
                        </Form.Group>
                        <div style={{color: 'red', padding: '5px'}}>{error}</div>
                        <Button disabled={isProcessing} variant="primary" type="submit">Sign In</Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Don't have an account yet? <a href="/signup">Sign up</a>
            </div>
        </div>
        </div>
    );
}