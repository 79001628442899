import React, { Component } from "react";

import '../../App.css';
import Stage0 from "./anxietyStages/Stage0";
import Stage1 from "./anxietyStages/Stage1";
import Stage2 from "./anxietyStages/Stage2";
import Stage3 from "./anxietyStages/Stage3";
import Stage4 from "./anxietyStages/Stage4";
import Stage5 from "./anxietyStages/Stage5";
import Stage6 from "./anxietyStages/Stage6";
import Stage7 from "./anxietyStages/Stage7";
import Stage8 from "./anxietyStages/Stage8";
import Stage9 from "./anxietyStages/Stage9";
import Stage10 from "./anxietyStages/Stage10";
import Stage11 from "./anxietyStages/Stage11";
import Stage12 from "./anxietyStages/Stage12";
import Stage13 from "./anxietyStages/Stage13";
import Results from "./anxietyStages/Results";
import BilateralStimulation from "../BilateralStimulation";
import Breathwork from "../Breathwork";

class Anxiety extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 0,
            target: "",
            sensory: "",
            emotion: "",
            physical: "",
            score1: null,
            score2: null,
            negBelief: "",
            posBelief: "",
            posScore1: null,
            posScore2: null,
        };
    };

    handleNext = () => {
        document.getElementById("scroller").scroll(0,0)
        this.setState({ stage: this.state.stage + 1 });
    }
    handleTargetSet = (newTarget) => {
        this.setState({ target: newTarget });
    }
    handleSensorySet = (newSensory) => {
        this.setState({ sensory: newSensory });
    }
    handleEmotionSet = (newEmotion) => {
        this.setState({ emotion: newEmotion });
    }
    handlePhysicalSet = (newPhysical) => {
        this.setState({ physical: newPhysical });
    }
    handleScore1Set = (newScore) => {
        this.setState({ score1: newScore });
    }
    handleScore2Set = (newScore) => {
        this.setState({ score2: newScore });
    }
    handleNegBeliefSet = (newNeg) => {
        this.setState({ negBelief: newNeg });
    }
    handlePosBeliefSet = (newPos) => {
        this.setState({ posBelief: newPos });
    }
    handlePosScore1Set = (newScore) => {
        this.setState({ posScore1: newScore });
    }
    handlePosScore2Set = (newScore) => {
        this.setState({ posScore2: newScore });
    }

    render() {
        return (
            <div className="emdr">
                {this.state.stage === 0 &&
                    <Stage0 handleNext={this.handleNext} />
                }
                {this.state.stage === 1 &&
                    <Stage1 handleTargetSet={this.handleTargetSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 2 &&
                    <Stage2 target={this.state.target} handleSensorySet={this.handleSensorySet} handleNext={this.handleNext} />
                }
                {this.state.stage === 3 &&
                    <Stage3 target={this.state.target} handleEmotionSet={this.handleEmotionSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 4 &&
                    <Stage4 target={this.state.target} handlePhysicalSet={this.handlePhysicalSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 5 &&
                    <Stage5 target={this.state.target} sensory={this.state.sensory} physical={this.state.physical} emotion={this.state.emotion} handleScore1Set={this.handleScore1Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 6 &&
                    <Stage6 target={this.state.target} sensory={this.state.sensory} physical={this.state.physical} emotion={this.state.emotion} handleNegBeliefSet={this.handleNegBeliefSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 7 &&
                    <Stage7 target={this.state.target} sensory={this.state.sensory} physical={this.state.physical} emotion={this.state.emotion} negBelief={this.state.negBelief} handleNext={this.handleNext} />
                }
                {this.state.stage === 8 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 9 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 10 &&
                    <Stage8 handlePosBeliefSet={this.handlePosBeliefSet} handleNext={this.handleNext} />
                }
                {this.state.stage === 11 &&
                    <Stage9 posBelief={this.state.posBelief} handlePosScore1Set={this.handlePosScore1Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 12 &&
                    <Stage10 posBelief={this.state.posBelief} handleNext={this.handleNext} />
                }
                {this.state.stage === 13 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 14 &&
                    <BilateralStimulation handleNext={this.handleNext} />
                }
                {this.state.stage === 15 &&
                    <Stage11 posBelief={this.state.posBelief} handlePosScore2Set={this.handlePosScore2Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 16 &&
                    <Stage12 handleNext={this.handleNext} />
                }
                {this.state.stage === 17 &&
                    <Breathwork handleNext={this.handleNext} />
                }
                {this.state.stage === 18 &&
                    <Stage13 target={this.state.target} handleScore2Set={this.handleScore2Set} handleNext={this.handleNext} />
                }
                {this.state.stage === 19 &&
                    <Results score1={this.state.score1} score2={this.state.score2} posScore1={this.state.posScore1} posScore2={this.state.posScore2} handleNext={this.handleNext} />
                }
                
            </div>
        );
    };
}

export default Anxiety;