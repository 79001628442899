import React from 'react';
import Accordion from 'react-bootstrap/Accordion';


function BoxBreathing(props) {
    return (
            
            <Accordion style={{width: '80%', margin:'10px'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p style={{fontWeight: 'bold'}}>Box Breathing</p>
                            <p style={{color: 'gray'}}> A simple, easy to remember breathwork practice</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{padding: '20px'}}>
                            <p style={{fontStyle: 'italic'}}>Also known as four-square breathing, box breathing is very simple and easy to remember. When you feel stressed or triggered try this breathing technique.</p>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>1 - Exhale to a count of four.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>2 - Hold your lungs empty for a four-count.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>3 - Inhale to a count of four.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>4 - Hold the air in your lungs for a count of four.</p>
                            <br></br>
                            <p style={{marginLeft: '30px', textIndent: '-25px'}}>5 - Exhale to count of four, beginning the pattern again.</p>

                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        
    );
}

export default BoxBreathing;