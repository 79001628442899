import Button from 'react-bootstrap/Button';
import { Navigate } from 'react-router-dom';
import { useState } from "react";
import { FaRegEye } from 'react-icons/fa';
import { RiPsychotherapyLine } from 'react-icons/ri';
import { AiOutlineLineChart } from 'react-icons/ai';
import { HiBadgeCheck } from 'react-icons/hi';
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from 'firebase/app';

import Checklist from './Checklist';
import { flattenOptionGroups } from '@mui/base';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function Home3() {

  const [navigation, setNavigation] = useState(null);

  const handleTryClicked = () => {
    logEvent(analytics, 'try_button_clicked');
    setNavigation(<Navigate to='/therapy' />)
  }
  const handleGetStartedClicked = () => {
    logEvent(analytics, 'get_started_button_clicked');
    setNavigation(<Navigate to='/signup' />)

  }
  const handleStart30DayClicked = () => {
    logEvent(analytics, 'start_trial_button_clicked');
    setNavigation(<Navigate to='/signup' />)

  }

  return (

      <div style={{height: 'calc(100vh - 100px)', overflowY: 'auto', overflowX: 'hidden', perspective: '10px', marginBottom: '-200px'}}>
        <div style={{display: 'flex', transformStyle: 'preserve-3d', justifyContent: 'center', top: '600px'}}>
          {/* <img style={{position: 'absolute', zIndex: '-1', transform: 'translateZ(-3.5px) scale(2)', width: '1500px', top: '-20px'}} src="neurons2_background.svg"></img> */}
          {/* <img className="neuronsBackground" style={{position: 'absolute', zIndex: '-1', transform: 'translateZ(-3.5px) scale(2)', width: '1500px', top: '200px'}} src="neurons_background.svg"></img> */}
          {/* <img style={{position: 'absolute', zIndex: '-1', transform: 'translateZ(-1px) scale(1.5)', width: '1500px', top: '-20px'}} src="neurons2_foreground.svg"></img> */}
          {/* <img className="neuronsBackground" style={{position: 'absolute', zIndex: '-1', transform: 'translateZ(-1px) scale(1.5)', width: '1500px', top: '200px'}} src="neurons_foreground.svg"></img> */}
        </div>
        <div style={{backgroundColor: 'purple', zIndex: '-10'}}>
          <div className='frame' style={{width: '1800px', height: '1420px', position: 'absolute', zIndex: '1', top :'-480px'}}>
            </div>
            
            <div className="photo">
            </div>
          </div>
        <div style={{position: 'relative', zIndex: '1'}}>
          <div>
              {navigation}
              <div style={{position: 'absolute', width: '100%', backgroundColor: 'white', height: '1000px', top: '1500px'}}></div>
              <div>
                <div className="fontLogo">nurosync</div>
                <div className="container"><img className="logo" src="nurosync_logo.svg" alt="Nurosync Brain Logo"></img></div>
                <h1 className="homeText" style={{maxWidth: '500px', margin: 'auto', color: 'white'}}>Get instant relief from anxiety, depression and trauma.</h1>
                <div className="start">
                  <Button className="start tryButton" onClick={() => handleTryClicked()}>Try Nurosync</Button>
                </div>
              </div>

              <div style={{width: '100%', height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: '20px', marginTop: '300px'}}>
                <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{margin: '10px'}}>
                    <HiBadgeCheck size={80} style={{color: 'dodgerBlue'}}/>
                  </div>
                  <div>
                    <p style={{fontSize: '30px', color: 'darkslategray', padding: '10px', position: 'relative', top: '5px'}}>100% Anonymous</p>
                  </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{margin: '10px'}}>
                    <HiBadgeCheck size={80} style={{color: 'dodgerBlue'}}/>
                  </div>
                  <div>
                    <p style={{fontSize: '30px', color: 'darkslategray', padding: '10px', position: 'relative', top: '5px'}}>Easy to use without a therapist</p>
                  </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{margin: '10px'}}>
                    <HiBadgeCheck size={80} style={{color: 'dodgerBlue'}}/>
                  </div>
                  <div>
                    <p style={{fontSize: '30px', color: 'darkslategray', padding: '10px', position: 'relative', top: '5px'}}>Unlimited sessions</p>
                  </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{margin: '10px'}}>
                    <HiBadgeCheck size={80} style={{color: 'dodgerBlue'}}/>
                  </div>
                  <div>
                    <p style={{fontSize: '30px', color: 'darkslategray', padding: '10px', position: 'relative', top: '5px'}}>Access from anywhere</p>
                  </div>
                </div>
                </div>
              </div>
              <div className="start">
                  <Button className="start tryButton" onClick={() => handleStart30DayClicked()}>Start 30 Day Free Trial</Button>
              </div>
              <div className="cards" style={{padding: '20px', display: 'flex', position: 'relative', width: '100%', top: '59px', marginBottom: '20px', textAlign:'center', backgroundColor: '#c473d6'}}>
                <div style={{flex: '1', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <RiPsychotherapyLine size={130} style={{color: 'white', padding: '5px'}} />
                  <h1 style={{fontSize: '37px', padding: '10px', color: 'white'}}>Therapy</h1>
                  <p style={{fontSize: '20px', color: 'white', padding: '10px', maxWidth: '350px'}}>Experience immediate and lasting relief from anxiety, depression, trauma and PTSD</p>
                </div>
                <div style={{ flex: '1', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <FaRegEye size={130} style={{color: 'white', padding: '5px'}} />
                  <h1 style={{fontSize: '37px', padding: '10px', color: 'white'}}>EMDR</h1>
                  <p style={{fontSize: '20px', color: 'white', padding: '10px', maxWidth: '350px'}}>Eye movement desentization and reprograming is proven safe and effective.</p>
                </div>
                <div style={{flex: '1', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <AiOutlineLineChart size={130} style={{color: 'white', padding: '5px'}}/>
                  <h1 style={{fontSize: '37px', padding: '10px', color: 'white'}}>Analytics</h1>
                  <p style={{fontSize: '20px', color: 'white', padding: '10px', maxWidth: '350px'}}>Track your therapy results over time so you can get control of your mental health.</p>
                </div>
              </div>


              <div style={{position: 'relative', top: '200px'}}>
                <Checklist />
              </div>
              <div className="start" style={{position: 'relative', top: '200px', margin: '20px'}}>
                <Button style={{marginBottom: '50px'}} className="start tryButton" href='/signup' onClick={() => handleGetStartedClicked()}>Get Started</Button>
              </div>
          </div>
        </div>
      </div>


  );
}

export default Home3;