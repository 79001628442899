import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import '../App.css';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, doc, setDoc, arrayUnion } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import app from "../firebase"
import Chart from './Chart';

const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth();

class EndScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            charts: [],
        }

    }

    addData = async (uid) => {
        console.log(uid)

        const docRef = doc(db, 'generalEMDRResults', uid);
        setDoc(docRef, {
            scores : arrayUnion({
                moodScore1: this.props.moodScore1,
                moodScore2: this.props.moodScore2,
                targetScore1: this.props.targetScore1,
                targetScore2: this.props.targetScore2,
                posScore1: this.props.posScore1,
                posScore2: this.props.posScore2,
                date: new Date(Date.now()).toLocaleString(),
            })
            
        }, {merge: true});

    }

    renderCharts = () => {
        const data = [{
            moodScore1: this.props.moodScore1,
            moodScore2: this.props.moodScore2,
            targetScore1: this.props.targetScore1,
            targetScore2: this.props.targetScore2,
            posScore1: this.props.posScore1,
            posScore2: this.props.posScore2,
        }]
        const moodChart = <Chart data={data} dataKey1="moodScore1" dataKey2="moodScore2"/>
        const targetChart = <Chart data={data} dataKey1="targetScore1" dataKey2="targetScore2"/>
        const posChart = <Chart data={data} dataKey1="posScore1" dataKey2="posScore2"/>
        
        let charts = [<h3 style={{textAlign: "center"}}>Mood Score</h3>, moodChart, <h3 style={{textAlign: "center"}}>Target Score</h3>, targetChart, <h3 style={{textAlign: "center"}}>Positive Score</h3>, posChart];

        this.setState({charts: charts});

    }

    componentDidMount() {
        logEvent(analytics, 'therapy_session_completed');

        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            let uid = user.uid;
            this.addData(uid);
            this.renderCharts()

            
            // ...
            } else {
            // User is signed out
            // ...
            this.addData(null);
            this.renderCharts()

            }
        });

    }




    render () {
        return (
            <div className="stage0">
                <h1>Session complete. Please take a few moments to ground yourself before moving on with your day.</h1>
                <h1>Results:</h1>
                {this.state.charts}

                <div className="start startButton">
                        <Button className="start" href="/">Done</Button>
                </div>
                {/* <div className="logo"><img className="bigLogo" src="nurosync_logo.png"></img></div> */}
            </div>
        )
    }

}

export default EndScreen;