import React from 'react';
import Accordion from 'react-bootstrap/Accordion';


function SevenEleven(props) {
    return (
            
            <Accordion style={{width: '80%', margin:'10px'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p style={{fontWeight: 'bold'}}>7-11 Breathing</p>
                            <p style={{color: 'gray'}}> A technique for handling anxiety</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{padding: '20px'}}>
                            Count to seven while inhaling. Then exhale for a count of eleven. This forces you to exhale more than you inhale which reduces anxiety. Try it before social events, or whenever you feel anxious.
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        
    );
}

export default SevenEleven;