import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Navigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

import app from "../firebase"


const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    await stripe.confirmSetup({
      redirect: 'if_required',

      elements,
      confirmParams: {
        // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        // return_url: 'https://example.com',
      },
    })
    .then(function(result) {
      if(result.error) {
        setMessage(result.error.message);
        setIsProcessing(false);
      }
      if(result.setupIntent.status === 'succeeded') {
        // fetch('/api/create-subscription', {
        //   method: 'post',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     email: props.email,
        //     payment_method: result.setupIntent.payment_method,
        //   }),
        const sendText = httpsCallable(functions, 'createSubscription')
        return sendText({email: props.email, payment_method: result.setupIntent.payment_method}, 'more stuff')
        .then(async (response) => {
          logEvent(analytics, 'subscription_started');
          updateUserDoc(props.uid, response.data.customerId, response.data.subscriptionId)
        })
      };
    });

  };

  const updateUserDoc = async (uid, customerId, subscriptionId) => {
    const userRef = await doc(db, "users", uid);
    await updateDoc(userRef, {
        customerId: customerId,
        subscriptionId: subscriptionId,
    });
    setCompleted(true);
  };

  return (
    <div 
    // style={{maxWidth:"90vw", width:"500px"}}
    >
      { completed && 
        <div>
          <Navigate to='/completion' replace={true} />
        </div>
      }
      <form id="payment-form" onSubmit={handleSubmit}>
        <h1>Nurosync Pro</h1>
        <h6>30 day free trial</h6>
        <h6>$4.99/month after trial expires</h6>
        <h6>Full access to our therapy app</h6>
        <h6>Cancel anytime</h6>
        <br></br>
        <PaymentElement id="payment-element" />
        <button disabled={isProcessing || !stripe || !elements} id="submit" class="btn btn-primary" style={{marginTop: "10px", }}>
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Start free trial"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
