import React from 'react';
import { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, setDoc, arrayUnion } from "firebase/firestore";

import app from "../../firebase"

const db = getFirestore(app);
const auth = getAuth();

class DepressionTest extends React.Component {

    state = ({
        group1: 0,
        group2: 0,
        group3: 0,
        group4: 0,
        group5: 0,
        group6: 0,
        group7: 0,
        group8: 0,
        group9: 0,
        total: 0,
        completed: false,
        resultText: '',
    });

    handleChange = e => {
        this.setState({ [e.target.name]: parseInt(e.target.value) });
    };

    handleSubmit = () => {
        this.setState({completed: true});
        let score = this.state.group1 + this.state.group2 + this.state.group3 + this.state.group4 + this.state.group5 + this.state.group6 + this.state.group7 + this.state.group8 + this.state.group9;
        this.setState({total: score});
        this.showResult(score);
        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
                let uid = user.uid;
                this.addData(uid, score);
            
            // ...
            } else {
            // User is signed out
            // ...

            }
        });
    }

    addData = async(userId, data) => {
        const date = new Date(Date.now()).toLocaleString();
        const docRef = doc(db, 'depressionTest', userId);
        setDoc(docRef, {
            scores : arrayUnion({date: date, score: data})
            
        }, {merge: true})

    }

    showResult = (score) => {
        if(score <= 4) {
            this.setState({resultText: "Result: " + score + " - Minimal depression"});
        } else if(score <= 9) {
            this.setState({resultText: "Result: " + score + " - Mild depression"});
        } else if(score <= 14) {
            this.setState({resultText: "Result: " + score + " - Moderate depression"});
        } else if(score <= 19) {
            this.setState({resultText: "Result: " + score + " - Moderately severe depression"});
        } else if(score >= 20) {
            this.setState({resultText: "Result: " + score + " - Severe depression"})
        }
    }


    render() {

        return (
                
                <Accordion style={{width: '80%', margin:'10px'}}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div>
                                <p style={{fontWeight: 'bold'}}>Depression Test </p>
                                <p style={{color: 'gray'}}>Measure your depression level so you can keep track of your progress</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div style={{padding: '20px'}}>
                                <p style={{fontStyle: 'italic'}}>Over the last two weeks, how often have you been bothered by any of the following problems?</p>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Little pleasure or interest in doing things</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group1"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group1"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group1"
                                            type="radio"
                                            value={2}

                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group1"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Feeling down, depressed or hopeless</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group2"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group2"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group2"
                                            type="radio"
                                            value={2}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group2"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Trouble falling or staying asleep, or sleeping too much</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group3"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group3"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group3"
                                            type="radio"
                                            value={2}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group3"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Feeling tired or having little energy</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group4"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group4"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group4"
                                            type="radio"
                                            value={2}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group4"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Poor appetite or overeating</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group5"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group5"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group5"
                                            type="radio"
                                            value={2}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group5"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Feeling bad about yourself or that you are a failure or
have let yourself or your family down</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group6"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group6"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group6"
                                            type="radio"
                                            value={2}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group6"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Trouble concentrating on things, such as reading the
newspaper or watching television</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group7"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group7"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group7"
                                            type="radio"
                                            value={2}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group7"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Moving or speaking so slowly that other people could
have noticed. Or the opposite being so figety or
restless that you have been moving around a lot more
than usual</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group8"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group8"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group8"
                                            type="radio"
                                            value={2}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group8"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Thoughts that you would be better off dead, or of hurting yourself</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Not at all"
                                            name="group9"
                                            type="radio"
                                            value={0}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Several days"
                                            name="group9"
                                            type="radio"
                                            value={1}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="More than half the days"
                                            name="group9"
                                            type="radio"
                                            value={2}
                                        />
                                        <Form.Check
                                            onChange={this.handleChange}
                                            label="Nearly every day"
                                            name="group9"
                                            type="radio"
                                            value={3}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>If you checked off any problems, how difficult have these problems made it for you to do
your work, take care of things at home, or get
along with other people?</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                        <Form.Check
                                            label="Not difficult at all"
                                            name="group10"
                                            type="radio"
                                        />
                                        <Form.Check
                                            label="Somewhat difficult"
                                            name="group10"
                                            type="radio"
                                        />
                                        <Form.Check
                                            label="Very difficult"
                                            name="group10"
                                            type="radio"
                                        />
                                        <Form.Check
                                            label="Extremely difficult"
                                            name="group10"
                                            type="radio"
                                        />
                                    </Form.Group>
                                    




                   
    
                                    <Button disabled={this.state.completed} onClick={() => this.handleSubmit()}variant="primary" >
                                        Submit
                                    </Button>
                                </Form>
                                <div style={{width: '100%', textAlign: 'center', margin: '10px', padding: '10px', fontSize: '33px'}}>
                                    {this.state.resultText}
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            
        );
    }
}

export default DepressionTest;