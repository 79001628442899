import { createContext, useContext, useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
// import { auth } from "../firebase"

const auth = getAuth();
const UserContext = createContext();

export const AuthContextProvider = ({children}) => {

    const [user, setUser] = useState({});

    const createUser = (auth, email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    }

    const logout = () => {
        return signOut(auth);
    }

    const currentUser = () => {
        

        return onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            // let uid = user.uid;
            return user
            }
            else return null
        })
        
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            // console.log(currentUser)
            setUser(currentUser)
        })
        return() => {
            unsubscribe();
        }
    },[])

    return (
        <UserContext.Provider value={{ createUser, user, logout, signIn, currentUser }}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(UserContext)
}