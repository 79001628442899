import Button from 'react-bootstrap/Button';
import { Navigate } from 'react-router-dom';
import { useState } from "react";
import { FaRegEye } from 'react-icons/fa';
import { RiPsychotherapyLine } from 'react-icons/ri';
import { AiOutlineLineChart } from 'react-icons/ai';
import { HiBadgeCheck } from 'react-icons/hi';
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from 'firebase/app';

import Checklist from './Checklist';
import { flattenOptionGroups } from '@mui/base';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function Home2() {

  const [navigation, setNavigation] = useState(null);

  const handleTryClicked = () => {
    logEvent(analytics, 'try_button_clicked');
    setNavigation(<Navigate to='/therapy' />)
  }
  const handleGetStartedClicked = () => {
    logEvent(analytics, 'get_started_button_clicked');
    setNavigation(<Navigate to='/signup' />)

  }
  const handleStart30DayClicked = () => {
    logEvent(analytics, 'start_trial_button_clicked');
    setNavigation(<Navigate to='/signup' />)

  }

  return (

    <div style={{backgroundColor: 'purple', zIndex: '-10'}}>
      <div style={{width: '500px', height: '1000px', backgroundColor: 'pink', borderRadius: '50%', position: 'absolute', zIndex: '1'}}>
      </div>
      
      <div className="photo">
      </div>
    </div>


  );
}

export default Home2;