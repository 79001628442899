import React, { useState }from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import DepressionTest from '../tools/DepressionTest';

function DepressionDashboardView(props) {

    const [component, setStateComponent] = useState(
        <div>
            <h1>Dashboard</h1>
            <Card style={{margin: '10px', maxWidth: '500px', maxHeight: '280px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
                <Card.Body>
                    <Card.Title>Recommended for you</Card.Title>
                    <div style={{padding: '10px'}}>
                        <Card.Subtitle className="mb-2 text-muted">Depression Test</Card.Subtitle>
                        <Card.Text>
                            Find your current level of depression so you can keep track of your progress.
                        </Card.Text>
                        <Button variant="primary" onClick={() => setComponent()}>Start</Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )

    const setComponent = () => {
        // setStateComponent(<DepressionTest />)
        props.showComponent(<DepressionTest />)
    }

    return (
        <div className='dashboardView'>
            {component}
        </div>
    );
}

export default DepressionDashboardView;