import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const auth = getAuth();

function ResetPassword(props) {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
        .then(() => {
        // Password reset email sent!
        // ..
            setMessage("Email sent!");
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setMessage(error.message);
        // ..
        });

    }

    return (
        <div className='container'>
         <div style={{width: "400px", padding: "20px"}}>
            <Card>
                <Card.Body>
                    <h3>Reset Password</h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" required placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Group>
                        <div>
                            {message}
                        </div>
                        <Button variant="primary" type="submit">Send Reset Email</Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Don't have an account yet? <a href="/signup">Sign up</a>
            </div>
          </div>
        </div>
    );
}

export default ResetPassword;