import React, { Component } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import app from "../../firebase"
import EMDRResultsChart from './EMDRResultsChart';
//TODO: Put DB in firebase.js and export it
const db = getFirestore(app);
const auth = getAuth();


class TraumaEMDRResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            charts: [],
            overlay: [],
        }
    }

    getData = async (userId) => {
        const docRef = doc(db, "traumaEMDRResults", userId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            let data = docSnap.data().scores;
            this.renderCharts(data);

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    renderCharts(data) {
        const traumaChart = <EMDRResultsChart data={data} dataKey1="traumaScore1" dataKey2="traumaScore2"/>
        const posChart = <EMDRResultsChart data={data} dataKey1="positiveScore1" dataKey2="positiveScore2"/>
        let charts = [<h3 style={{textAlign: "center"}}>Trigger Score</h3>, traumaChart, <h3 style={{textAlign: "center"}}>Positive Score</h3>, posChart];
        this.setState({charts: charts});

    }


    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            let uid = user.uid;
            this.getData(uid);

            // ...
            } else {
            // User is signed out
            // ...
            // this.renderChartsHidden()
            this.getResultData(null);

            }
        });

    }

    render() {

        return (
            <div>
                {this.state.overlay}
                <div style={{display: "block"}} id="children-pane">
                    <h1>Trauma EMDR Results</h1>
                    
                    {this.state.charts}
                </div>

            </div>

        );
    }

}

export default TraumaEMDRResults;