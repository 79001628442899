import React, { useState } from 'react';
import { getAuth, sendEmailVerification} from 'firebase/auth';
import { Button } from 'react-bootstrap';

const auth = getAuth();


function NotVerified(props) {
    const [message, setMessage] = useState(null);

    const handleSendEmail = () => {
        sendEmailVerification(auth.currentUser)
        .then(() => {
            setMessage('Email verification sent!')
            // ...
        }).catch((error) => {
            setMessage(error);
            console.log('Error fetching user data:', error);
        });
    }
    return (
        <div>
            <h1>Your account is unverified. You must verify your account before using this feature.</h1>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <div style={{padding: "10px"}}>{message}</div>
                <Button onClick={handleSendEmail}>Send Verification Email</Button>
            </div>
        </div>
    );
}

export default NotVerified;