import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getAnalytics, logEvent } from "firebase/analytics";

import NavBar from './components/NavBar';
import AppRouter from './components/AppRouter';
import { AuthContextProvider } from "./context/AuthContext"

const trackingId = "G-MK55S6XCM7";

ReactGA.initialize(trackingId);
ReactGA.set({
  username: localStorage.getItem('userName'),
  // Other relevant user information
})

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');


function App() {
  return (
    <div className="App application">
      <AuthContextProvider>
        <NavBar />
        <AppRouter />
      </AuthContextProvider>
    </div>
  );
}

export default App;
