import React from 'react';

function Completion(props) {
    return (
        <div>
            <h1>Success! Your subscription is now active!</h1>
        </div>
    );
}

export default Completion;