import React from 'react';
import { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, doc, addDoc , query, where, setDoc, getDoc } from "firebase/firestore";

import app from "../../firebase"

const db = getFirestore(app);
const auth = getAuth();

class GoalsWorksheet extends React.Component {

    state = ({
        signedIn: false,
        buttonDisabled: false,
        problem: "",
        difference: "",
        goal1: "",
        goal2: "",
        goal3: "",
        change1: "",
        change2: "",
        change3: "",
    });

    handleChange = e => {
        if(this.state.signedIn) {
            this.setState({buttonDisabled: false});
        }
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = () => {
        this.setState({ buttonDisabled: true });
        let responses = [];


        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
                let uid = user.uid;
                this.addData(uid, this.state);
            
            // ...
            } else {
            // User is signed out
            // ...

            }
        });
    }

    addData = async(userId, data) => {
        await setDoc(doc(db, "goalsWorksheet", userId), {
            responses: this.state,
        });
    }

    getdata = async(userId) => {
        const docRef = doc(db, "goalsWorksheet", userId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            
            let data = docSnap.data().responses
            this.setState({problem: data.problem});
            this.setState({difference: data.difference})
            this.setState({goal1: data.goal1})
            this.setState({goal2: data.goal2})
            this.setState({goal3: data.goal3})
            this.setState({change1: data.change1})
            this.setState({change2: data.change2})
            this.setState({change3: data.change3})
        } else {
            // doc.data() will be undefined in this case
        }
    }

    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
                let uid = user.uid;
                this.setState({ signedIn: true })
                this.getdata(uid)
            
            // ...
            } else {
            // User is signed out
            // ...
                this.setState({ buttonDisabled: true })

            }

        });

    };   

    render() {

        return (
                
                <Accordion style={{width: '80%', margin:'10px'}}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div>
                                <p style={{fontWeight: 'bold'}}>Therapy Goals Worksheet </p>
                                <p style={{color: 'gray'}}>Define your goals for therapy so you can stay on track and measure your progress</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div style={{padding: '20px'}}>
                                <p style={{fontStyle: 'italic'}}></p>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>What problem(s) have lead you to start therapy?</Form.Label>
                                        <Form.Control name="problem" placeholder="..." value={this.state.problem} onChange={this.handleChange}/>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Imagine you wave a magic wand and the problem(s) you wrote about above have been solved. What has changed? How is your life different?</Form.Label>
                                        <Form.Control name="difference" placeholder="..." value={this.state.difference} onChange={this.handleChange}/>
                                    </Form.Group>

                                    <p style={{fontWeight: 'bold'}}>What are three goals you would like to work on during your therapy sessions?</p>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Goal 1:</Form.Label>
                                        <Form.Control name="goal1" placeholder="..." value={this.state.goal1} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Goal 2:</Form.Label>
                                        <Form.Control name="goal2" placeholder="..." value={this.state.goal2} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Goal 3:</Form.Label>
                                        <Form.Control name="goal3" placeholder="..." value={this.state.goal3} onChange={this.handleChange}/>
                                    </Form.Group>

                                    <p style={{fontWeight: 'bold'}}>For each of the goals listed above, describe how your life would change if you acheived this goal.</p>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Life change 1:</Form.Label>
                                        <Form.Control name="change1" placeholder="..." value={this.state.change1} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Life change 2:</Form.Label>
                                        <Form.Control name="change2" placeholder="..." value={this.state.change2} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: 'bold'}}>Life change 3:</Form.Label>
                                        <Form.Control name="change3" placeholder="..." value={this.state.change3} onChange={this.handleChange}/>
                                    </Form.Group>
    
                                    <Button onClick={() => this.handleSubmit()} variant="primary" disabled={this.state.buttonDisabled} >
                                        Save
                                    </Button>
                                </Form>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            
        );
    }
}

export default GoalsWorksheet;