import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Button, Card }from 'react-bootstrap';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { UserAuth } from "../context/AuthContext";
import { getFirestore, doc, getDoc } from "firebase/firestore";

import app from "../firebase"

import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
  } from "firebase/functions";

const functions = getFunctions(app);
const auth = getAuth();
const db = getFirestore(app);

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionStatus: null,
            user: null,
            logOut: false,
            subscribe: false,
        }
    }

    handleSignOut = async () => {
        try{
            await signOut(auth);
            // await logout
            this.setState({ logOut: true });
        } catch (e) {
            // console.log(e.message)
        }

    }

    handleSubscribe = () => {
        this.setState({ subscribe: true });
    }

    handleCancelSubscription = async () => {
        const docRef = doc(db, "users", this.state.user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const sendText = httpsCallable(functions, 'cancelSubscription')
            sendText(docSnap.data().subscriptionId, 'more stuff')
            .then(response => {
                  // Display to the user that the subscription has been canceled.
                  this.setState({ subscriptionStatus: "canceled" });
            });

        } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
        }

    }

    getSubscriptionStatus = async (uid) => {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if(docSnap.data().subscriptionId) {
            try {
                const sendText = httpsCallable(functions, 'getSubscriptionStatus')
                sendText(docSnap.data().subscriptionId, 'more stuff').then(response => {
                    if(response.data.status) {
                        this.setState({ subscriptionStatus: response.data.status });
                    } else {
                        this.setState({ subscriptionStatus: "inactive" });
                    }
    
                })
            } catch (err) { console.log(err) }
        } else {
            // No subscription id on user document...
            this.setState({ subscriptionStatus: "inactive" });
        }


    }

    // getSubscriptionStatus = async (uid) => {
    //     const docRef = doc(db, "users", uid);
    //     const docSnap = await getDoc(docRef);
    //     if(docSnap.data().subscriptionId) {
    //         return fetch('/api/get-subscription', {
    //             method: 'post',
    //             headers: {
    //               'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //               subscriptionId: docSnap.data().subscriptionId,
    //             }),
    //         }).then(response => {
    //             console.log(response);
    //             return response.json();
    //         }).then(getSubscriptionResponse => {
    //             this.setState({ subscriptionStatus: getSubscriptionResponse.status })
    //         });
    //     } else {
    //         // No subscription id on user document...
    //         this.setState({ subscriptionStatus: "inactive" });
    //     }

    // }

    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            // let uid = user.uid;
            this.setState({ user: user });
            this.getSubscriptionStatus(user.uid);
            } else {
                this.setState({ user: false });
            }
        })

    }
    render() {

    
        return (
            <div>
                { this.state.logOut ? 
                    <Navigate to='/' />
                :
                    <div></div>
                }
                { this.state.subscribe ? 
                    <Navigate to='/pro' />
                :
                    <div></div>
                }
                {this.state.user === null &&
                    <div style={{display: 'flex', height: 'calc(100vh - 100px)', justifyContent: 'center', alignItems: 'center'}}>
                        <img style={{position: 'relative', width: '50px', height:'50px', bottom: '30px' }}src="https://media.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />
                    </div>
                } 
                {this.state.user &&
                    <Card style={{width: "400px", padding: "30px", margin: "auto", marginTop: "20px"}}>
                        <h1>Account</h1>
                        <div>
                            <h3>You are signed in as:</h3>
                            <p>User Email: {this.state.user.email}</p>
                            <Button style={{width: "90px"}} variant="primary" onClick={this.handleSignOut}>Sign Out</Button>
                            <br></br>
                            <br></br>
                            <hr></hr>
                            {this.state.subscriptionStatus === null &&
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img style={{position: 'relative', width: '25px', height:'25px' }}src="https://media.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />
                                </div>
                            }
                            {(this.state.subscriptionStatus === "active" || this.state.subscriptionStatus === "trialing") &&
                                <div>
                                    <p>Subscription Status: {this.state.subscriptionStatus}</p>
                                    <Button style={{width: "120px"}} variant="danger" onClick={this.handleCancelSubscription}>Cancel Subscription</Button>
                                </div>
                            }
                            {!(this.state.subscriptionStatus === "active" || this.state.subscriptionStatus === "trialing" || this.state.subscriptionStatus === null) &&
                                <div>
                                    <p>Subscription Status: {this.state.subscriptionStatus}</p>
                                    <Button style={{width: "120px"}} variant="primary" onClick={this.handleSubscribe}>Activate Subscription</Button>
                                </div>
                            }
                        </div>
                    </Card>
                }
                {this.state.user === false &&
                    <Card style={{width: "400px", padding: "30px", margin: "auto", marginTop: "20px"}}>
                        <div>
                            <h3>You are not signed in.</h3>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20px", marginRight: "20px"}}>
                                <p style={{flex: 1}}> <a href="/signin">Sign In</a></p>
                                <p style={{flex: 1, textAlign: "right"}}><a href="/signup">Create Account</a></p>
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    };
}

export default Account;